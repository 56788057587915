import React, { Component } from 'react';
import { Container, Heading } from './styled';
import ProfileSidebar from './ProfileSidebar';
import ProfileSection from './ProfileSection';
import NotificationsSection from './NotificationsSection';
import PasswordSection from './PasswordSection';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = { section: 'profile' };
  }
  render() {
    let content = <div></div>;

    switch (this.state.section) {
    case 'profile':
      content = <ProfileSection />;
      break;
    case 'notifications':
      content = <NotificationsSection />;
      break;
    case 'password':
      content = <PasswordSection />;
      break;
    default:
      break;
    }
    return (
      <Container>
        <Heading>Account Settings</Heading>
        <div style={{ display: 'flex' }}>
          <ProfileSidebar
            updatePage={(section) => this.setState({ section })}
            profilePage={this.state.section} {...this.props} />
          {content}
        </div>
      </Container>
    );
  }
}

export default Profile;