import React from 'react';
import Image from '../../../../ImageBasic';
import Divider from '../../../../Divider';
import Row from '../../../../Row';
import Column from '../../../../Column';
import EditSVG from './EditIcon.svg';
import BoostSVG from './BoostIcon.svg';
import BoostSVGDisabled from './BoostIconDisabled.svg';
import DressAnalytics from './DressAnalytics';
import {
  ItemContainer,
  ItemHeader,
  ItemTitle,
  ItemPicture,
  Label,
  ItemFooterHeading,
  ItemFooterContent,
  Unpublished,
} from './styled';

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selected: false };
  }
  render() {
    const {
      name,
      __id,
      photos: [image],
      boostDate,
      accountType,
      views = [],
      likes = 0,
      showBoost,
      unpublished,
      selected,
      disabled,
      edit,
      style,
    } = this.props;
    return (
      <ItemContainer
        disabled={disabled}
        selected={selected && !disabled}
        onClick={() => !disabled && edit(__id)}>
        <ItemHeader>
          {unpublished ? <Unpublished>Unpublished</Unpublished> : null}
          <ItemTitle>{name}</ItemTitle>
        </ItemHeader>
        <ItemPicture>
          <img src={image} />
        </ItemPicture>
        <Column>
          <Row style={{
            height: 57, justifyContent: 'center', alignItems: 'center',
            width: '100%',
          }}
          >
            <Row style={{ flex: 1, justifyContent: 'center' }}>
              <Image onClick={() => {
                this.props.edit(__id);
              }} src={EditSVG}
              />
              <Label style={{ margin: 20 }}>Edit</Label>
            </Row>
            {(accountType === 1 && showBoost) && (
              <>
                <Divider vertical />
                <Row style={{
                  flex: 1, justifyContent: 'center', alignItems:'center',
                }}>
                  <Image style={{ height: 30 }} onClick={() => {
                    if (!boostDate) {
                      this.props.boost(__id);
                    } else {
                      alert('This listing has already been boosted');
                    }
                  }} src={boostDate ? BoostSVGDisabled : BoostSVG}
                  />
                  <Label style={{ margin: 20, color: boostDate ? '#E1CFCD' : '#FE95A2' }}>Boost</Label>
                </Row>
              </>
            )}
          </Row>
          <Divider />
          <Row style={{
            margin: '12px 0',
            justifyContent:'space-around', width:'100%', flex: 1,
          }}
          >
            <Column style={{
              justifyContent: 'center', alignItems:'center', flex:1,
            }}
            >
              <ItemFooterHeading>{views.length}</ItemFooterHeading>
              <ItemFooterContent>Impressions</ItemFooterContent>
            </Column>
            <Divider style={{ height: 'calc(100% + 24px)', transform:'translateY(-12px)' }} vertical />
            <Column style={{
              justifyContent: 'center', alignItems:'center', flex: 1,
            }}
            >
              <ItemFooterHeading>{likes}</ItemFooterHeading>
              <ItemFooterContent>Likes</ItemFooterContent>
            </Column>
          </Row>
          <Divider />
          <DressAnalytics views={views} />
        </Column>
      </ItemContainer>
    );
  }
}

export default Item;
