import React from 'react';
import { connect } from 'react-redux';
import { deleteSeller } from '../../../../../actions/userActions';
import Divider from '../../../../Divider';
import Column from '../../../../Column';
import Button from '../../../../Button1';
import Regular from '../../../../Text/Regular';
import { Heading, Title } from './styled';

function Cancel({ deleteSeller }) {
  return (
    <Column style={{
      margin: 30, backgroundColor: 'white', justifyContent: 'flex-start',
      width: '100%',
    }}>
      <Heading>
        <Title>Cancel My Account</Title>
      </Heading>
      <Divider />
      <Regular style={{ marginTop: 28, marginLeft: 40 }}>We’re sad to see you go. Please confirm your cancellation by clicking the button below.</Regular>
      <Button style={{ width: 228, margin: '20px 40px' }} onClick={() => {
        const res = confirm('Are you sure you want to permanently delete your account?');

        if (res) {
          deleteSeller();
        }
      }}>Confirm Cancellation</Button>
    </Column>
  );
}

const mapDispatchToProps = { deleteSeller };

export default connect(null, mapDispatchToProps)(Cancel);