import React from 'react';
import { connect } from 'react-redux';
import Item from '../../../Item';
import {
  Container, Heading, BodyHeading,
  BodySubHeading, Body, Divider, Items,
} from './styled';

function FittingRoom({ accountData, products }) {
  const { favoriteListings = [] } = accountData || {};
  const items = favoriteListings.map((id) => products[id]).filter((exists) => !!exists);
  return (
    <Container>
      <Heading>Fitting Room</Heading>
      <Body>
        <BodyHeading>Fitting Room #1</BodyHeading>
        <BodySubHeading>Your favorite gowns</BodySubHeading>
        <Divider />
        <Items>
          {
            items.map((item_data, index) => (
              <Item favorite={favoriteListings.includes(item_data.__id)} key={index} {...item_data} />
            ))
          }
        </Items>
      </Body>
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state.userReducer, ...state.shopReducer });

export default connect(mapStateToProps)(FittingRoom);
