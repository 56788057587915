import React, { useEffect, useState } from 'react';
import H3 from '../../../../Text/H3';
import BodyText from '../../../../Text/Body';
import TextArea from '../../../../TextArea';
import Input from '../../../../ThemedInput';
import Card from '../../../../Card';
import Divider from '../../../../Divider';
import Button from '../../../../Button1';
import { STATE_OPTIONS } from '../../../../../constants';
import ThemedDropdown from '../../../../ThemedDropdown';
import { ProfileInputs } from './styled.js';

function ProfileInformation({ accountData: _accountData, updateAccountData }) {
  const { profile = {}, address = {} } = _accountData || {};
  const [accountData, setAccountData] = useState({ ...address, ...profile });

  useEffect(() => {
    setAccountData({ ...profile, ...address });
  }, [address, profile]);
  return (
    <Card style={{ margin: 15 }}>
      <H3 style={{ marginBottom: 7 }}>Basic Profile</H3>
      <BodyText style={{ marginBottom: 15 }}>This information can be edited from your profile page.</BodyText>
      <Divider style={{ width: 'calc(100% + 40px)', transform: 'translateX(-20px)' }} />
      <ProfileInputs>
        <Input label="First Name" value={accountData.firstName} update={(firstName) => setAccountData({
          ...accountData,
          firstName,
        })}/>
        <Input label="Last Name" value={accountData.lastName} update={(lastName) => setAccountData({
          ...accountData,
          lastName,
        })}/>
        <Input label="Email" value={accountData.email} update={(email) => setAccountData({
          ...accountData,
          email,
        })}/>
        <Input label="Phone Number" value={accountData.phoneNumber} update={(phoneNumber) => setAccountData({
          ...accountData,
          phoneNumber,
        })}/>
        <Input label="Street 1" value={accountData.street1} update={(street1) => setAccountData({
          ...accountData,
          street1,
        })}/>
        <Input label="Street 2" value={accountData.street2} update={(street2) => setAccountData({
          ...accountData,
          street2,
        })}/>
        <Input label="City" value={accountData.city} update={(city) => setAccountData({
          ...accountData,
          city,
        })}/>
        <ThemedDropdown options={STATE_OPTIONS} label="State" value={accountData.state} update={(state) => setAccountData({
          ...accountData,
          state,
        })}/>
        <Input label="Zip" value={accountData.zip} update={(zip) => setAccountData({
          ...accountData,
          zip,
        })}/>
      </ProfileInputs>
      <Divider style={{
        width: 'calc(100% + 40px)',
        transform: 'translateX(-20px)',
        marginTop: 30,
      }} />
      <H3 style={{ marginTop: 15 }}>About Me</H3>
      <BodyText style={{ marginBottom: 4 }}>Share your bridal story. .</BodyText>
      <TextArea value={accountData.aboutMe} update={(aboutMe) => setAccountData({
        ...accountData,
        aboutMe,
      })} />
      <Divider style={{
        width: 'calc(100% + 40px)',
        transform: 'translateX(-20px)',
        marginTop: 20,
      }} />
      <Button style={{ width: 228, marginTop: 20 }} onClick={() => updateAccountData({
        profile: {
          firstName: accountData.firstName,
          lastName: accountData.lastName,
          email: accountData.email,
          phoneNumber: accountData.phoneNumber,
          avatar: accountData.avatar,
          aboutMe: accountData.aboutMe,
        },
        address: {
          city: accountData.city,
          state: accountData.state,
          country: accountData.country,
          zip: accountData.zip,
          street1: accountData.street1,
          street2: accountData.street2,
        },
      })}>Save</Button>
    </Card>
  );
}

export default ProfileInformation;