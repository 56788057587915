import React, { useState } from 'react';
import { searchForMessage } from '../../../../../helpers/Format/index.js';
import {
  Container, Input, SearchIcon,
} from './styled.js';
import SearchSVG from './search_icon.svg';

function SearchBar({ messages, update }) {
  const [searchValue, setSearchValue] = useState(null);

  function search(_searchValue) {
    const searchResults = searchForMessage(_searchValue, messages);
    setSearchValue(_searchValue);
    update(searchResults);
  }
  return (
    <Container>
      <SearchIcon src={SearchSVG}/>
      <Input placeholder="Search Inbox" value={searchValue} onChange={(e) => search(e.target.value)} />
    </Container>
  );
}

export default SearchBar;