import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updatePassword } from '../../../../../actions/userActions';
import Input from '../../../../ThemedInput';
import {
  Container, Heading, Divider, Body, SaveButton,
} from './styled';
const input_forms = [
  {
    label: 'OLD PASSWORD',
    id: 'oldPassword',
  },
  {},
  {
    label: 'NEW PASSWORD',
    id: 'newPassword',
  },
  {
    label: 'CONFIRM PASSWORD',
    id: 'newPasswordConfirm',
  },
];

class PasswordSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { updatePassword } = this.props;
    return (
      <Container>
        <Heading>
          <div>Change your Password</div>
          <div>Save your settings before existing</div>
        </Heading>
        <Divider />
        <Body>
          {
            input_forms.map(({ id, label }, index) => !id ? <div /> : (
              <Input
                onChange={(val) => this.setState({ [id]: val })}
                key={index}
                label={label}
                value={this.state[id]} />
            ))
          }
        </Body>
        <Divider />
        <SaveButton onClick={() => updatePassword(this.state)}>Save</SaveButton>
      </Container>
    );
  }
}


const mapStateToProps = (state) => ({ ...state.userReducer });

const mapDispatchToProps = { updatePassword };


export default connect(mapStateToProps, mapDispatchToProps)(PasswordSection);
