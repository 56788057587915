import React from 'react';
import moment from 'moment';
import {
  Container, ProfileImage , ItemContainer, Name,
  Message, Time, Indicator,
} from './styled.js';

function MessageList({
  searchResults, currentMessageId, selectMessage,
}) {
  return (
    <Container>
      {
        Object.values(searchResults).map(({
          name, avatar, thread,
        }, index) => {
          if (!thread || !thread.length) {
            return <div />;
          }

          const active = thread.find(({ id }) => id === currentMessageId);
          return (
            <ItemContainer onClick={() => selectMessage(thread.slice(-1)[0])} active={active} key={index}>
              <ProfileImage src={avatar} />
              <div>
                <Name>{name}</Name>
                <Message>{thread.slice(-1)[0].message}</Message>
              </div>
              <Time>{moment(thread.slice(-1)[0].date).fromNow()}</Time>
              {active && <Indicator />}
            </ItemContainer>
          );
        })
      }
    </Container>
  );
}

export default MessageList;