import styled from 'styled-components';
import { NavigationStyles } from '../../../Text/Navigation';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
export const MenuItemContainer = styled(NavigationStyles)`
  color: ${props => props.active ? 'white' : '#6B6C6F'};
  padding: 16px;
  border-radius: 4px;
  width: 213px;
  background-color: ${props => props.active ? '#E1CFCD' : ''};
`;

export const ProfileNavigation = styled.div`
  margin: 0 30px;
  margin-top: 26px;
  margin-bottom: 50px;
`;

export const PackageType = styled.div`
  
`;

export const Name = styled.div`
  
`;

export const ProfilePicture = styled.img`
  width: calc(100% - 30px);
  object-fit: contain;
  margin-bottom: 26px;
  margin: auto;
`;

export const NameDetails = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 30px;
  margin-bottom: 23px;
  margin-top: 26px;
`;