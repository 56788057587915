import styled from 'styled-components';

export const Container = styled.div`
  margin: 2.188vw;
  display: flex;
  margin-right: 9.375vw;
  background: white;
  flex-direction: column;
`;

export const Heading = styled.div`
  display: grid;
  padding: 0 1.938vw;
  background: #FFFFFF;
  box-shadow: 0 20px 20px 0 rgba(0,0,0,0.05);
  height: 4.313vw;
  grid-template-columns: 32.60fr 30.43fr 18.32fr 18.63fr;
  > div {
    display: flex;
    align-items: center;
    > img {
      cursor: pointer;
      width: 0.6250vw;
      height: 0.3750vw;
      object-fit: contain;
      margin-left: 0.6250vw;
    }
  }
`;

export const Orders = styled.div`
  height: 33.94vw;
  overflow-y: scroll;
  flex-direction: column;
  margin-top: 1.938vw;
  > div:last-child {
    border-bottom: 0;
  }
`;

export const Label = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 0.8750vw;
  color: #AAAAAA;
  letter-spacing: 1.4px;
  line-height: 18px;
  align-self: center;
  cursor: pointer;
`;