import React, { useState } from 'react';
import Card from '../../../../Card';
import H3 from '../../../../Text/H3';
import BodyText from '../../../../Text/Body';
import Divider from '../../../../Divider';
import Button from '../../../../Button1';
import Input from '../../../../Input';
import Row from '../../../../Row';
// import { Container } from './styled.js';

function Password({ updatePassword }) {
  const [newPassword, setNewPassword] = useState(null);
  const [newPasswordConfirm, setNewPasswordConfirm] = useState(null);
  return (
    <Card style={{ margin: 15 }}>
      <H3 style={{ marginBottom: 7 }}>Change Your Password</H3>
      <BodyText style={{ marginBottom: 15 }}>Save your settings before exiting</BodyText>
      <Divider style={{ width: 'calc(100% + 40px)', transform: 'translateX(-20px)' }} />
      <Row style={{ width: '100%', marginTop: 24 }}>
        <Input style={{ marginRight: 10 }} label="New Password" value={newPassword} update={setNewPassword} />
        <Input label="Confirm Password" value={newPasswordConfirm} update={setNewPasswordConfirm} />
      </Row>
      <Button style={{ width: 228, marginTop: 20 }} onClick={() => updatePassword({
        newPassword,
        newPasswordConfirm,
      })}>Save</Button>
    </Card>
  );
}

export default Password;