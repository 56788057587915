import styled from 'styled-components';

export const MemberType = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #9EA0A5;
  line-height: 22px;
`;

export const Container = styled.div`
  width: 21.88vw;
  margin-left: 2.750vw;
  margin-top: 1.875vw;
  display: flex;
  flex-direction: column;
  > img {
    object-fit: cover;
    width: 21.88vw;
    height: 18.13vw;
  }
`;

export const Summary = styled.div`
`;

export const Edit = styled.img`
  object-fit: contain;
  height: 20px;
  width: 20px;
  cursor: pointer;
`;

export const Name = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 22px;
  color: #E1CFCD;
  line-height: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const NameContainer = styled.div`
  padding-top: 23px;
  padding-left: 1.875vw;
  background-color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 24px;
  border-bottom: 2px solid #EAEDF3;
  > div:nth-child(3) {
    position: relative;
    background-color: #E1CFCD;
    width: 11.19vw;
    min-height: 1.625vw;
    margin-bottom: 0.8750vw;
    border-radius: 0.2500vw;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: sofia-pro, sans-serif;
  font-weight: 400;
    font-size: 0.8750vw;
    line-height: 22px;
    cursor: pointer;
    margin-top: 1.063vw;
    > img:nth-child(1) {
      position: absolute;
      top: -0.47vw;
      left: 2.375vw;
    }
  }
`;
export const Details = styled.div`
  height: 12.44vw;
  background-color: white;
  display: flex;
  flex-direction: column;
  > div:nth-child(1) {
    font-family: sofia-pro, sans-serif;
  font-weight: 400;
    font-size: 0.7500vw;
    color: #9EA0A5;
    line-height: 18px;
    text-transform: uppercase;
    margin-left: 1.875vw;
    margin-top: 1.625vw;
  }
`;
export const MenuItem = styled.div`
  display: flex;
  margin: 0 0.8750vw;
  height: 2.500vw;
  background-color: ${props => props.active ? '#E1CFCD' : undefined};
  align-items: center;
  border-radius: 0.2500vw;
  cursor: pointer;
  > div:nth-child(1) {
    margin-left: 1.000vw;
    color: ${props => props.active ? '#FFFFFF' : '#6B6C6F'};
    font-family: sofia-pro, sans-serif;
  font-weight: 400;
    font-size: 0.8750vw;
    line-height: 22px;
  }
`;