import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { navigate } from '@reach/router';
import * as utils from '../../helpers/Format';
import { getQueryString } from '../../helpers/Fetch';
import { favoriteListing } from '../../helpers/API';
import {
  ItemContainer,
  ItemHeader,
  New,
  ItemTitle,
  ItemPicture,
  ItemDetailsContainer,
  ItemPrice,
  ItemSalePrice,
  ItemListNumber,
  ItemCondition,
  ItemFooter,
  Row,
} from './styled';
import heart from './HeartIcon.svg';
import heart_filled from './HeartIconFilled.svg';

function Item({
  date,
  name,
  price,
  salePrice,
  __id,
  condition,
  photos,
  favorite,
  footer,
  user,
}) {
  const [image] = photos || [];
  const [liked, setLiked] = useState(favorite);

  useEffect(() => {
    setLiked(favorite);
  }, [favorite]);

  const new_product = moment().diff(moment(date), 'days') < 3;
  return (
    <ItemContainer>
      <ItemHeader onClick={() => navigate(`/item/${__id}`)}>
        {new_product && !footer && <New>NEW</New>}
        <ItemTitle>{name}</ItemTitle>
      </ItemHeader>
      <ItemPicture onClick={() => navigate(`/item/${__id}`)}>
        <img src={image} />
      </ItemPicture>
      <ItemDetailsContainer onClick={() => navigate(`/item/${__id}`)}>
        <Row>
          <ItemListNumber>Listing #{__id}</ItemListNumber>
          <ItemCondition>Condition: {condition}</ItemCondition>
        </Row>
        <Row>
          <ItemPrice>${utils.formatAmount(price)}</ItemPrice>
          <ItemSalePrice>${utils.formatAmount(salePrice)}</ItemSalePrice>
        </Row>
      </ItemDetailsContainer>
      {footer ? footer() : <ItemFooter onClick={(e) => {
        if (e.target.id !== 'favorite-button') {
          navigate(`/item/${__id}`);
        }
      }}>
        <div style={{ cursor: 'pointer' }} onClick={() => navigate(`/item/${__id}`)}>Show Details</div>
        <img id="favorite-button" style={{ cursor: 'pointer', pointerEvents: 'all' }} onClick={() => {
          if (!user || (user && user.isAnonymous)) {
            const goTologin = confirm('You must be logged in to favorite a dress, please login in first.');

            if (goTologin) {
              const payload = {
                redirect: true,
                type: 'like_dress',
                dress_id:__id,
                selected: !favorite,
              };
              navigate(`/login?${getQueryString(payload)}`);
            }
          } else {
            setLiked(!liked);
            favoriteListing(__id);
          }
        }} src={liked ? heart_filled : heart} />
      </ItemFooter>}
    </ItemContainer>
  );
}

const mapStateToProps = (state) => ({ ...state.userReducer });

export default connect(mapStateToProps)(Item);
