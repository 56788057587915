import styled from 'styled-components';

export const Container = styled.div`
  height: 50px;
  width:  100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
`;

export const Input = styled.input`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #9FA9BA;
  background-color: transparent;
  height: 100%;
  width: calc(100% - 20px);
  ::placeholder {
    font-family: sofia-pro, sans-serif;
  font-weight: 400;
    font-size: 18px;
    color: #9FA9BA;
  }
`;

export const SearchIcon = styled.img`
  height: 13px;
  width: 13px;
  margin-left: 17px;
  margin-right: 11px;
`;