import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const QuestionText = styled.i`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #464855;
  margin-top: 16px;
  margin-bottom: 20px;
`;