import React, { useState } from 'react';
import { connect } from 'react-redux';
import Card from '../../../Card';
import Column from '../../../Column';
import Row from '../../../Row';
import Image from '../../../ImageBasic';
import H3 from '../../../Text/H3';
import H4 from '../../../Text/H4';
import Body from '../../../Text/Body';
import Divider from '../../../Divider';
import TextArea from '../../../TextArea';
import Button from '../../../Button1';
import { answerQuestion } from '../../../../actions/userActions';
import { Container, QuestionText } from './styled.js';

function Notifications({ questions = [], answerQuestion }) {
  const [response, setResponse] = useState(null);
  return (
    <Container>
      {
        questions.map(({
          firstName, lastName, question, avatar, listing, id,
        }, index) => (
          <Card bodyStyle={{ width: '100%' }} style={{ margin: 10 }} key={index}>
            <Column style={{ margin: 10 }}>
              <H3>New Question</H3>
              <Body>Someone asked a question about your dress</Body>
            </Column>
            <Divider style={{ width: 'calc(100% + 20px)', transform: 'translateX(-10px)' }} />
            <Column style={{ margin: '18px 27px' }}>
              <Row>
                <Image style={{
                  height: 60, width: 60, borderRadius:'50%', marginRight: 20,
                }} src={avatar} />
                <Column>
                  <H4>{firstName}&nbsp;{lastName}</H4>
                  <Body>asked...</Body>
                </Column>
              </Row>
              <QuestionText>
                {question}
              </QuestionText>
              <TextArea style={{ marginBottom: 15 }} value={response} update={setResponse} />
              <Divider style={{ width: 'calc(100% + 52px)', transform: 'translateX(-27px)' }} />
            </Column>
            <Button onClick={() => answerQuestion(listing, id, response)} style={{
              margin: 'auto', marginTop: 23, width: 'calc(100% - 28px)',
            }}>Respond Publicly</Button>
          </Card>
        ))
      }
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state.userReducer });
const mapDispatchToProps = { answerQuestion };

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);