import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
`;

export const Name = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 1.250vw;
  color: #354052;
  border-bottom: 2px solid #EAEDF3;
  display: flex;
  align-items: center;
  padding-left: 2.438vw;
  background-color: white;
  height: 3.688vw;
`;

export const Thread = styled.div`
  height: 45.31vw;
  overflow-y: scroll;
`;

export const Message = styled.div`
  min-height: 3.438vw;
  padding-left: 3.438vw;
  display: flex;
  align-items: flex-start;
  margin-top: 1.375vw;
  position: relative;
  > img:nth-child(1) {
    flex-shrink: 0;
    height: 2.500vw;
    width: 2.500vw;
    object-fit: contain;
    border-radius: calc(2.500vw / 2);
    margin-right: 2.188vw;
  }
  > div:nth-child(2) {
    min-height: 3.438vw;
    > div:nth-child(1) {
      font-family: sofia-pro, sans-serif;
  font-weight: 700;
      font-size: 16px;
      color: #354052;
      margin-bottom: 0.6875vw;
    }
    > div:nth-child(2) {
      font-family: sofia-pro, sans-serif;
  font-weight: 700;
      font-size: 14px;
      color: #354052;
      line-height: 21px;
      width: 40.06vw;
    }
  }
`;

export const Time = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 0.8750vw;
  color: #AAB2C0;
  text-align: right;
  line-height: 21px;
  position: absolute;
  right: 4.500vw;
  top: 0.6250vw;
`;

export const Send = styled.div`
  width: 6.063vw;
  height: 2.250vw;
  background-color: #FE95A2;
  border-radius: 0.2500vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 0.8750vw;
  color: #FFFFFF;
  text-align: center;
  cursor: pointer;
`;

export const Input = styled.input`
  height: 2.250vw;
  flex: 1;
  border-radius: 0.2500vw;
  border: 1px solid #E6EAEE;
  padding-left: 1vw;
  margin-right: 1.375vw;
`;

export const MessageInput = styled.div`
  margin: auto 0;
  display: flex;
  margin-right: 1.375vw;
  background: #FFFFFF;
  margin-left: 2.688vw;
  border-radius: 0.2500vw;
  justify-content: space-between;
`;