import React from 'react';
import { getBase64 } from '../../helpers/Utils';
import { Container, Input } from './styled';

function ImageUpload({
  style, onChange, children,
  accepts = 'image/png, image/jpeg',
}) {
  return (
    <Container style={style}>
      <Input onChange={(e) => {
        const photo = e.target.files[0];
        getBase64(photo).then(onChange);
      }} accept={accepts} type="file" />
      {children}
    </Container>
  );
}

export default ImageUpload;