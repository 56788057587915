import styled from 'styled-components';

export const Container = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  img {
    width: 1.000vw;
    height: 1.000vw;
    margin: 1.438vw 0.9375vw 1.438vw 1.375vw; 
  }
  input {
    font-family: sofia-pro, sans-serif;
  font-weight: 700;
    font-size: 1.125vw;
    color: #9FA9BA;
  }
`;