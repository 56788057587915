import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  margin: 0 1.938vw;
  padding: 1.250vw 0;
  border-bottom: 3px solid #E7E7E7;
  min-height: 3.562vw;
  grid-template-columns: 32.60fr 30.43fr 18.32fr 18.63fr;
  > div:nth-child(4) {
    position: relative;
  }
`;

export const OrderNumber = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 0.9375vw;
  color: #FE95A2;
  letter-spacing: 1.5px;
  line-height: 18px;
`;

export const CustomerInfo = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 0.8125vw;
  color: #888888;
  letter-spacing: 0;
  line-height: 26px;
`;

export const Address = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 0.9375vw;
  color: #222222;
  letter-spacing: 1.5px;
  line-height: 20px;
  width: 12.50vw;
`;

export const Date = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 0.9375vw;
  color: #222222;
  letter-spacing: 1.5px;
  line-height: 18px;
`;
export const Total = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 0.9375vw;
  color: #222222;
  letter-spacing: 1.5px;
  line-height: 18px;
`;

export const PaymentType = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 0.8125vw;
  color: #888888;
  letter-spacing: 1.3px;
  line-height: 24px;
`;