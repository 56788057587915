import React from 'react';
import { Container, Body } from './styled.js';

function Card({
  children, bodyStyle, ...props
}) {
  return (
    <Container {...props}>
      <Body style={bodyStyle}>
        {children}
      </Body>
    </Container>
  );
}

export default Card;