import styled from 'styled-components';

export const Container = styled.div`
  width: 25.12vw;
  height: 54.37vw;
  background-color: white;
  border-right:  1px solid #E6EAEE;
  display: flex;
  flex-direction: column;
`;

export const ScrollContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

export const ItemContainer = styled.div`
  position: relative;
  height: 4.313vw;
  display: flex;
  padding: 0.6875vw 1.875vw;
  align-items: center;
  border-bottom: 1px solid #E6EAEE;
  cursor: pointer;
  background-color: ${props => props.active ? '#FEFAFA' : 'white'};
  img {
    flex-shrink: 0;
    margin-right: 0.9375vw;
    height:2.563vw;
    width: 2.563vw;
    border-radius: calc(2.563vw / 2);
  }
`;

export const Name = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 0.8750vw;
  color: #354052;
  margin-bottom: 0.6875vw;
`;

export const LastMessage = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 0.8750vw;
  color: #AAB2C0;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 12.88vw;
`;

export const Time = styled.div`
  position: absolute;
  opacity: 0.3;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #354052;
  text-align: right;
  top: 0.5vw;
  right: 1.875vw;
`;

export const Indicator = styled.div`
  background-color: #FE95A2;
  width: 4px;
  height: 100%;
  position: absolute;
  left: -1px;
  top: 0;
`;