import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import TextArea from '../../../../TextArea';
import { updateAccountData } from '../../../../../actions/userActions';
import {
  Container, Heading, Body, SaveButton, Divider,
} from './styled';
import Input from './Input';
const input_forms = [
  {
    label: 'First Name',
    id: 'firstName',
  },
  {
    label: 'Last Name',
    id: 'lastName',
  },
  {
    label: 'Email Address',
    id: 'email',
  },
  {
    label: 'Phone Number',
    id: 'phoneNumber',
  },
  {
    label: 'Address',
    id: 'street1',
  },
  {
    label: 'City',
    id: 'city',
  },
  {
    label: 'State',
    id: 'state',
  },
  {
    label: 'Zip Code',
    id: 'zip',
  },
];


function ProfileSection({
  accountData: _accountData, updateAccountData, accountType,
}) {
  const { profile = {}, address = {} } = _accountData || {};
  const [accountData, setAccountData] = useState({ ...address, ...profile });

  useEffect(() => {
    setAccountData({ ...profile, ...address });
  }, [address, profile]);
  return (
    <Container>
      <Heading>
        <div>Basic Profile</div>
        <div>This information can be edited from your profile page</div>
      </Heading>
      <Divider />
      <Body>
        {
          input_forms.map(({ id, label }, index) => (
            <Input
              onChange={(val) => setAccountData({
                ...accountData,
                [id]: val,
              })}
              key={index}
              label={label}
              value={accountData[id]} />
          ))
        }
      </Body>
      <Divider />
      { accountType !== 2 && (
        <>
          <Heading>
            <div>About Me</div>
            <div>Share your bridal story.</div>
          </Heading>
          <TextArea value={accountData.aboutMe} style={{
            height: 144, width: 'calc(100% - 80px)',
            marginBottom: 40, margin: 'auto',
          }} update={(val) => {
            setAccountData({
              ...accountData,
              ['aboutMe']: val,
            });
          }}></TextArea>
          <Divider style={{ marginTop: 40 }} />
        </>
      )}
      <SaveButton onClick={() => updateAccountData({
        profile: {
          firstName: accountData.firstName,
          lastName: accountData.lastName,
          email: accountData.email,
          phoneNumber: accountData.phoneNumber,
          avatar: accountData.avatar,
          aboutMe: accountData.aboutMe,
        },
        address: {
          city: accountData.city,
          state: accountData.state,
          country: accountData.country,
          zip: accountData.zip,
          street1: accountData.street1,
        },
      })}>Save</SaveButton>
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state.userReducer });

const mapDispatchToProps = { updateAccountData };

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSection);
