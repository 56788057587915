import React from 'react';
import moment from 'moment';
import {
  Container, OrderNumber, CustomerInfo, Address, Date, Total, PaymentType,
} from './styled';
import OptionsIcon from './OptionsIcon.svg';

function OrderDetail({
  __id,
  firstName,
  lastName,
  email,
  address:{
    street,
    city,
    state,
    zip,
  },
  date,
  amount,
  paymentType = 'Credit Card',
}) {
  const name = `${firstName} ${lastName}`;
  return (
    <Container>
      <div>
        <OrderNumber>#{__id}</OrderNumber>
        <CustomerInfo>
          <div>{name}</div>
          <div>{email}</div>
        </CustomerInfo>
      </div>
      <Address>{street} {city}, {state} {zip}</Address>
      <Date>{moment(date).format('MM/DD/YYYY')}</Date>
      <div>
        <Total>${parseFloat(amount / 100).toFixed(2)}</Total>
        <PaymentType>{paymentType}</PaymentType>
      </div>
    </Container>
  );
}

export default OrderDetail;

