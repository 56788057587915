import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  margin: 40px;
`;

export const Heading = styled.div`
  padding: 0 40px;
  margin-bottom: 24px;
`;

export const Title = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #124C56;
  line-height: 28px;
`;

export const SubTitle = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #6B969D;
  line-height: 22px;
`;

export const Tier = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #124C56;
  letter-spacing: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #7DBEC8;
  height: 100%;
`;

export const Left = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #124C56;
  letter-spacing: 2px;
  span {
    color: #7DBEC8;
    font-weight: 700;
  }
`;

export const Right = styled.div`
  margin-left: auto;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #124C56;
  letter-spacing: 2px;
  text-align: right;
  span {
    font-weight: 700;
  }
`;

export const Footer = styled.div`
  margin: 40px auto 0;
  width: calc(100% - 80px);
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #124C56;
  letter-spacing: 0;
  text-align: center;
  line-height: 22px;
  span {
    color: #7DBEC8;
    font-weight: 700;
  }
  span:nth-child(4) {
    color: #EAAFB7;
    font-weight: 700;
  }
`;