import React from 'react';
import Card from '../../../../Card';
import H3 from '../../../../Text/H3';
import BodyText from '../../../../Text/Body';
import Regular from '../../../../Text/Regular';
import Row from '../../../../Row';
import Image from '../../../../ImageBasic';
import { formatAmount } from '../../../../../helpers/Format';

function Boosts({ listings }) {
  const boosted = listings.filter(({ boostDate }) => !!boostDate);
  return (
    <Card style={{ margin: 15 }}>
      <H3 style={{ marginBottom: 7 }}>Boosts</H3>
      <BodyText style={{ marginBottom: 15 }}>These are the listings you currently have boosted</BodyText>
      {
        boosted.map(({
          photos:[photo], name, salePrice,
        }, index) => (
          <>
            <Row key={index} style={{
              paddingTop:30, width: '100%', marginTop: 24,
              borderTop: '1px solid #EAEDF3', alignItems: 'center',
            }}>
              <Image style={{
                height: 88, width: 'auto', marginRight: 13,
              }} src={photo} />
              <Regular>{name}</Regular>
              <Regular style={{ marginLeft: 'auto' }}>${formatAmount(salePrice)}</Regular>
            </Row>
          </>
        ))
      }
    </Card>
  );
}

export default Boosts;