import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Heading, BodyHeading,
  BodySubHeading, Divider, Body,
} from './styled';

function Notifications(props) {
  const { notifications } = props;
  return (
    <Container>
      <Heading>Notifications</Heading>
      { notifications.map(({
        component, id, title, subTitle,
      }) =>
        <Body key={id}>
          <BodyHeading>{title}</BodyHeading>
          <BodySubHeading>{subTitle}</BodySubHeading>
          <Divider />
          {component}
        </Body>)}
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state.notificationsReducer });

export default connect(mapStateToProps)(Notifications);
