import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  margin: 0 30px;
  padding: 30px 0;
  border-bottom: 3px solid #E7E7E7;
  min-height: 113px;
  grid-template-columns: 1fr 1fr;
`;

export const OrderNumber = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #FE95A2;
  letter-spacing: 1.5px;
  line-height: 18px;
`;

export const Total = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #222222;
  letter-spacing: 1.5px;
  line-height: 18px;
`;

export const PaymentType = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: #888888;
  letter-spacing: 1.3px;
  line-height: 24px;
`;