import React from 'react';
import { formatNumberInput } from '../../helpers/Format/numbers.js';
import Image from '../ImageBasic';
import {
  Container, Label, Select, ChildContainer,
} from './styled.js';
import IconSVG from './dropdown_icon.svg';

function ThemedDropdown({
  label = '', type: _type, update, value,
  required, pattern, labelStyle, max,
  children, disabled, options, ...other
}) {
  let type = _type;

  if (_type === 'amount' || _type === 'number') {
    type = 'tel';
  }

  if (_type === 'amount' && value) {
    value = formatNumberInput(value);
  }

  if (max && value) {
    value = value.substr(0, max);
  }

  return (
    <Container {...other}>
      <Label shrink={label.length > 10} style={labelStyle}>{label}</Label>
      {!children ? (
        <Select disabled={disabled} pattern={pattern} type={type} required={required} value={value} onChange={(e) => update(e.target.value)}>
          {<option value="">

          </option>}
          {
            options.map((key) =>
              <option key={key}>{key}</option>,
            )
          }
        </Select>
      ) :
        <ChildContainer disabled={disabled}>
          {children}
        </ChildContainer>
      }
      <Image style={{
        position: 'absolute', top:'64%', right: 20,
      }} src={IconSVG} />
    </Container>
  );
}

export default ThemedDropdown;