import React, { useState } from 'react';
import Utility from '../../../Text/Utility';
import H2 from '../../../Text/H2';
import Body from '../../../Text/Body';
import { getPackageNameFromType } from '../../../Desktop/Dashboard/Profile/ProfileSidebar/helpers';
import Divider from '../../../Divider';
import Card from '../../../Card';
import ImageUpload from '../../../ImageUpload';
import ProfileInformation from './ProfileInformation';
import Notifications from './Notifications';
import Password from './Password';
import {
  Container, NameDetails, ProfilePicture,
  ProfileNavigation, MenuItemContainer,
  Edit, EditContainer,
} from './styled.js';
import EditIcon from './edit_icon.svg';

function Profile({
  accountData , updateAccountData, updatePassword,
}) {
  const {
    accountType,
    profile: {
      avatar, firstName, lastName,
    },
  } = accountData;
  const [activeSection, setActiveSection] = useState(0);
  return (
    <Container>
      <Card style={{ marginBottom: 30, alignItems: 'flex-start' }}>
        <EditContainer>
          <ProfilePicture src={avatar}/>
          <ImageUpload onChange={(res) => {
            updateAccountData({
              profile: {
                ...accountData.profile,
                avatar: res,
              },
            });
          }}>
            <Edit src={EditIcon} />
          </ImageUpload>
        </EditContainer>
        <NameDetails>
          <H2>{firstName}&nbsp;{lastName}</H2>
          <Body>{getPackageNameFromType(accountType)}</Body>
        </NameDetails>
        <Divider />
        <ProfileNavigation>
          <Utility style={{ marginBottom: 9 }}>Your Details</Utility>
          <MenuItemContainer active={activeSection === 0} onClick={() => setActiveSection(0)}>Profile</MenuItemContainer>
          <MenuItemContainer active={activeSection === 1} onClick={() => setActiveSection(1)}>Notifications</MenuItemContainer>
          <MenuItemContainer active={activeSection === 2} onClick={() => setActiveSection(2)}>Password</MenuItemContainer>
        </ProfileNavigation>
      </Card>
      <ProfileInformation accountData={accountData} updateAccountData={updateAccountData} />
      <Notifications accountData={accountData} updateAccountData={updateAccountData}/>
      <Password updatePassword={updatePassword} />
    </Container>
  );
}

export default Profile;