import styled from 'styled-components';
import Column from '../../../Column';

export const Note = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: #124C56;
  text-align: center;
  margin-top: 20px;
`;

export const NewItemContainer = styled(Column)`
  border: 2px solid #7DBEC8;
  justify-content: center;
  align-items: center;
  height: 560px;
  width: 300px;
  background-color: white;
  cursor: pointer;
  :hover {
    background-color: #DBE6E4;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Body = styled.div`
  margin: 0 auto;
  display: flex;
`;

export const Heading = styled.div`
  margin-top: 20px;
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  color: #3E3F42;
  line-height: 28px;
  display: flex;
  align-items: center;
`;

export const Listings = styled.div`
  display: flex;
`;