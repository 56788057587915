import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Heading, Orders, Label,
} from './styled';
import DownArrow from './DownArrow.svg';
import OrderDetail from './OrderDetail';
const headings = ['Order', 'Ship to', 'Date', 'Total'];

function OrderFrom({ orders }) {
  return (
    <Container>
      <Heading>
        {headings.map((label, index) => (
          <div key={index}>
            <Label>{label}</Label>
            <img src={DownArrow} />
          </div>
        ))}
      </Heading>
      <Orders>
        {
          orders.map((order, index) => (
            <OrderDetail key={index} {...order} />
          ))
        }
      </Orders>
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state.userReducer });
export default connect(mapStateToProps)(OrderFrom);
