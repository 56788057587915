import React from 'react';
import { connect } from 'react-redux';
import Card from '../../../../Card';
import H3 from '../../../../Text/H3';
import BodyText from '../../../../Text/Body';
import Divider from '../../../../Divider';
import Button from '../../../../Button1';
import Regular from '../../../../Text/Regular';
import { deleteSeller } from '../../../../../actions/userActions';

function Cancel({ deleteSeller }) {
  return (
    <Card style={{ margin: 15 }}>
      <H3 style={{ marginBottom: 7 }}>Cancel My Account</H3>
      <BodyText style={{ marginBottom: 15 }}>Note: Any active listings will be taken down. If you want to return, you’ll need a new account.</BodyText>
      <Divider style={{ width: 'calc(100% + 40px)', transform: 'translateX(-20px)' }} />
      <Regular style={{ marginTop: 28 }}>We’re sad to see you go. Please confirm your cancellation by clicking the button below.</Regular>
      <Button style={{ width: 228, marginTop: 20 }} onClick={() => {
        const res = confirm('Are you sure you want to permanently delete your account?');

        if (res) {
          deleteSeller();
        }
      }}>Confirm Cancellation</Button>
    </Card>
  );
}

const mapDispatchToProps = { deleteSeller };

export default connect(null, mapDispatchToProps)(Cancel);