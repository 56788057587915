import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  grid-template-columns: ${props => props.layout};
`;

function Grid({
  children, layout, ...props
}) {
  return (
    <Container layout={layout} {...props}>
      {children}
    </Container>
  );
}

export default Grid;