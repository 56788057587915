import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
`;

export const Name = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #354052;
  border-bottom: 2px solid #EAEDF3;
  display: flex;
  align-items: center;
  padding-left: 40px;
  background-color: white;
  height: 60px;
`;

export const Thread = styled.div`
  height: 400px;
  overflow-y: scroll;
  margin-top: 20px;
`;

export const Message = styled.div`
  min-height: 100px;
  padding-left: 3.438vw;
  display: flex;
  align-items: flex-start;
  margin-top: 1.375vw;
  position: relative;
`;

export const Time = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #AAB2C0;
  text-align: right;
  line-height: 21px;
  position: absolute;
  right: 4.500vw;
  top: 10px;
`;

export const Send = styled.div`
  width: 100px;
  height: 40px;
  background-color: #FE95A2;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sofia-pro, sans-serif; 
  font-weight: 700;
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
  cursor: pointer;
`;

export const Input = styled.input`
  height: 40px;
  flex: 1;
  border-radius: 4px;
  border: 1px solid #E6EAEE;
  padding-left: 10px;
  margin-right: 22px;
`;

export const MessageInput = styled.div`
  margin: auto 0;
  display: flex;
  margin-right: 55px;
  background: #FFFFFF;
  margin-left: 40px;
  border-radius: 4px;
  justify-content: space-between;
`;

export const ProfileImage = styled.img`
  flex-shrink: 0;
  margin-right: 20px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
`;

export const Content = styled.div`
  display: flex;
flex-direction: column;
`;

export const ContentName = styled.div`
  font-family: sofia-pro, sans-serif; 
  font-weight: 700;
  font-size: 16px;
  color: #354052;
`;

export const ContentMessage = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #354052;
  line-height: 21px;
  margin-top: 10px;
`;