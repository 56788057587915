import React from 'react';
import { connect } from 'react-redux';
import { getPackageNameFromType } from '../Profile/ProfileSidebar/helpers';
import { MemberType } from '../Profile/ProfileSidebar/styled';
import {
  Container, ActiveIndicator, Heading,
  Username, MainMenu, MenuItem,
} from './styled';
import ProfileIcon from './ProfileIcon.svg';
import OrderHistoryIcon from './OrderHistoryIcon.svg';
import InboxIcon from './InboxIcon.svg';
import NotificationsIcon from './NotificationsIcon.svg';
import FittingRoomIcon from './FittingRoomIcon.svg';
import MyListingsIcon from './MyListingsIcon.svg';
import ProfileIconActive from './ProfileIconActive.svg';
import InboxIconActive from './InboxIconActive.svg';
import NotificationsIconActive from './NotificationsIconActive.svg';
import FittingRoomIconActive from './FittingRoomIconActive.svg';
import MyListingsIconActive from './MyListingsIconActive.svg';
import OrderHistoryIconActive from './OrderHistoryIconActive.svg';
import PackageIcon from './PackageIcon.svg';
import PackageIconActive from './PackageIconActive.svg';

export const user_pages = [
  {
    name: 'Profile',
    id: 'profile',
    img: ProfileIcon,
    imgActive: ProfileIconActive,
  },
  {
    name: 'Inbox',
    id: 'inbox',
    img: InboxIcon,
    imgActive: InboxIconActive,
  },
  {
    name: 'Notifications',
    id: 'notifications',
    img: NotificationsIcon,
    imgActive: NotificationsIconActive,
  },
  {
    name: 'Fitting Room',
    id: 'fitting-room',
    img: FittingRoomIcon,
    imgActive: FittingRoomIconActive,
  },
  {
    name: 'Order History',
    id: 'orders',
    img: OrderHistoryIcon,
    imgActive: OrderHistoryIconActive,
  },
];

export const seller_pages_boutique = [
  {
    name: 'Profile',
    id: 'profile',
    img: ProfileIcon,
    imgActive: ProfileIconActive,
  },
  {
    name: 'Inbox',
    id: 'inbox',
    img: InboxIcon,
    imgActive: InboxIconActive,
  },
  {
    name: 'Notifications',
    id: 'notifications',
    img: NotificationsIcon,
    imgActive: NotificationsIconActive,
  },
  {
    name: 'Fitting Room',
    id: 'fitting-room',
    img: FittingRoomIcon,
    imgActive: FittingRoomIconActive,
  },
  {
    name: 'My Listings',
    id: 'my-listings',
    img: MyListingsIcon,
    imgActive: MyListingsIconActive,
  },
  {
    name: 'Order History',
    id: 'orders',
    img: OrderHistoryIcon,
    imgActive: OrderHistoryIconActive,
  },
  {
    name: 'My Package',
    id: 'package-details',
    img: PackageIcon,
    imgActive: PackageIconActive,
  },
];

export const seller_pages = [
  {
    name: 'Profile',
    id: 'profile',
    img: ProfileIcon,
    imgActive: ProfileIconActive,
  },
  {
    name: 'Inbox',
    id: 'inbox',
    img: InboxIcon,
    imgActive: InboxIconActive,
  },
  {
    name: 'Notifications',
    id: 'notifications',
    img: NotificationsIcon,
    imgActive: NotificationsIconActive,
  },
  {
    name: 'Fitting Room',
    id: 'fitting-room',
    img: FittingRoomIcon,
    imgActive: FittingRoomIconActive,
  },
  {
    name: 'My Listings',
    id: 'my-listings',
    img: MyListingsIcon,
    imgActive: MyListingsIconActive,
  },
  {
    name: 'Order History',
    id: 'orders',
    img: OrderHistoryIcon,
    imgActive: OrderHistoryIconActive,
  },
  {
    name: 'My Package',
    id: 'package',
    img: PackageIcon,
    imgActive: PackageIconActive,
  },
];

function SideBar({
  pageIndex, changePage, accountData,
  accountType,
}) {
  const { profile } = accountData || {};
  const {
    avatar, firstName = '', lastName = '',
  } = profile || {};
  return (
    <Container>
      <Heading>
        <img src={avatar} />
        <Username>
          <div>{firstName} {lastName}</div>
          <MemberType>{getPackageNameFromType(accountType)}</MemberType>
        </Username>
      </Heading>
      <MainMenu>Main Menu</MainMenu>
      {
        (accountType === 0 ? seller_pages_boutique : accountType === 1 ? seller_pages : user_pages).map(({
          name, id, img, imgActive,
        }, index) => (
          <MenuItem active={pageIndex === id} onClick={() => changePage(id)} key={index}>
            {pageIndex === id && <ActiveIndicator />}
            <img src={pageIndex === id ? imgActive : img} />
            <div>{name}</div>
          </MenuItem>
        ))
      }
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state.userReducer });

const mapDispatchToProps = {};


export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
