import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { declineOffer, acceptOffer } from '../../../../actions/userActions';
import { Container, Heading } from './styled';
import MessageList from './MessageList';
import CurrentMessage from './CurrentMessage';

function Messages({
  messages:_messages, accountType, accountData, user, declineOffer, acceptOffer,
}) {
  const { profile } = accountData || {};
  let messages = _messages;

  if (accountType === 2) {
    Object.keys(messages).forEach(userKey => {
      let { thread } = messages[userKey];
      const _thread = thread.slice(0);

      messages[userKey].thread = _thread;
    });
  }

  const [currentMessageThread, setCurrentMessageThread] = useState(null);
  const [search, setSearch] = useState({});

  useEffect(() => {
    if (!currentMessageThread && Object.keys(messages).length) {
      setCurrentMessageThread(Object.values(messages)[0].thread.slice(-1)[0]);
    }
    setSearch(messages);
  }, [currentMessageThread, messages]);

  let currentMessageList = Object.keys(search).length;

  if (currentMessageList) {
    currentMessageList = Object.values(search).find(({ thread }) => thread.find(({ id }) => id === currentMessageThread.id));
  }
  console.log('search', search);
  console.log('currentMessageList', currentMessageList);
  return (
    <Container>
      <Heading>Inbox</Heading>
      <div style={{ display: 'flex' }}>
        <MessageList
          profile={profile}
          searchResults={search}
          setSearch={setSearch}
          selectMessage={setCurrentMessageThread}
          currentMessageId={currentMessageThread && currentMessageThread.id}
          messages={messages} />
        {currentMessageThread && <CurrentMessage acceptOffer={acceptOffer} declineOffer={declineOffer} user={user} profile={profile} accountType={accountType} {...currentMessageList} />}
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state.userReducer });

const mapDispatchToProps = { acceptOffer, declineOffer };

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
