import React, { useState, useEffect } from 'react';
import Divider from '../../../Divider';
import { Container } from './styled.js';
import SearchBar from './SearchBar';
import MessageList from './MessageList';
import CurrentUserMessage from './CurrentUserMessage';

function Messages({
  messages, accountType, accountData, user,
}) {
  const [currentMessage, setCurrentMessage] = useState(null);
  const [search, setSearch] = useState({});
  const [showList, setShowList] = useState(true);

  useEffect(() => {
    if (!currentMessage && Object.keys(messages).length) {
      setCurrentMessage(Object.values(messages)[0].thread.slice(-1)[0]);
    }
    setSearch(messages);
  }, [currentMessage, messages]);

  const currentUser = Object.values(search)[0];
  return (
    <Container>
      <Divider />
      <SearchBar update={setSearch} messages={messages} />
      <Divider />
      {(currentMessage && !showList) ? (
        <CurrentUserMessage
          accountData={accountData}
          user={user}
          back={() => {
            setShowList(true);
          }} accountType={accountType} {...currentUser}/>
      ) : (
        <MessageList
          searchResults={search}
          setSearch={setSearch}
          selectMessage={(message) => {
            setCurrentMessage(message);
            setShowList(false);
          }}
          currentMessageId={currentMessage && currentMessage.id}
          messages={messages}/>
      )
      }
    </Container>
  );
}

export default Messages;