import React from 'react';
import { Container, Label } from './styled';

function Input({
  onChange, value, label,
}) {
  return (
    <Container>
      <Label>{label}</Label>
      <input onChange={({ target: { value } }) => onChange(value)} value={value} />
    </Container>
  );
}

export default Input;
