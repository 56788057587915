import styled from 'styled-components';

export const Container = styled.div`
  margin: 30px 15px;
  border-radius: 4px;
`;

export const Body = styled.div`
  margin: 20px;
`;

export const ProfileInputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
  margin-top: 14px;
`;