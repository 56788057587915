import React, { useState } from 'react';
import Utility from '../../../Text/Utility';
import H2 from '../../../Text/H2';
import Body from '../../../Text/Body';
import { getPackageNameFromType } from '../../../Desktop/Dashboard/Profile/ProfileSidebar/helpers';
import Divider from '../../../Divider';
import Card from '../../../Card';
import PackageChart from './PackageChart';
import Cancel from './Cancel';
import {
  Container, NameDetails, ProfilePicture,
  ProfileNavigation, MenuItemContainer,
} from './styled.js';

function PackageDetails({ accountData }) {
  const {
    accountType,
    profile:{
      avatar, firstName, lastName,
    },
  } = accountData;
  const [activeSection, setActiveSection] = useState(0);
  return (
    <Container>
      <Card style={{ marginBottom: 30, alignItems: 'flex-start' }}>
        <ProfilePicture src={avatar}/>
        <NameDetails>
          <H2>{firstName}&nbsp;{lastName}</H2>
          <Body>{getPackageNameFromType(accountType)}</Body>
        </NameDetails>
        <Divider />
        <ProfileNavigation>
          <Utility style={{ marginBottom: 9 }}>Your Details</Utility>
          <MenuItemContainer active={activeSection === 0} onClick={() => setActiveSection(0)}>Package Details</MenuItemContainer>
          <MenuItemContainer active={activeSection === 1} onClick={() => setActiveSection(1)}>Cancel</MenuItemContainer>
        </ProfileNavigation>
      </Card>
      <PackageChart accountData={accountData} />
      <Cancel />
    </Container>
  );
}

export default PackageDetails;