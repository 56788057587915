import React from 'react';
import Column from '../../../../Column';
import Row from '../../../../Row';
import Grid from '../../../../Grid';
import {
  Heading, Title, SubTitle,
  Tier, Left, Right, Footer,
} from './styled';

const tiers = [
  { amount:[1], price: 25 },
  { amount:[2, 5], price: 20 },
  { amount: [6, 100], price: 15 },
  { amount: [101, 200], price: 10 },
  { amount: [200], price: 5 },
];

function PackageChart({ accountData }) {
  const { listings } = accountData || {};
  const bracket = listings.length;
  const currentTierIndex = tiers.findIndex(({ amount }) => amount[0] > bracket) - 1 || 1;
  return (
    <Column style={{
      backgroundColor: 'white', justifyContent: 'flex-start',
      width: '100%', padding: '30px 0',
    }}>
      <Heading>
        <Title>SoBridely Package Details</Title>
        <SubTitle>See which tier you’re on so you know how close you are to your next big discount!</SubTitle>
      </Heading>
      <Column style={{
        marginTop: 24, borderBottom: '1px solid #7DBEC8', marginLeft: 41,
        width: 'calc(100% - 80px)',
      }}>
        {
          tiers.map(({ amount, price }, index) => (
            <Grid style={{
              width: '100%',
              backgroundColor: bracket + 1 >= amount[0] ? '#E5F2F4' : 'white',
              border: '1px solid #7DBEC8', borderBottom: 'none', height: 72,
              alignItems: 'center',
            }} key={amount.join(' - ')} layout="2fr 6fr">
              <Tier>TIER {index + 1}</Tier>
              <Row style={{ margin: '0 30px' }}>
                <Left>LIST <span>{amount.join(' - ')}</span> DRESSES</Left>
                <Right><span>${price}</span> PER DRESS</Right>
              </Row>
            </Grid>
          ))
        }
      </Column>
      <Footer>You currently have <span>{listings.length}</span> dresses listed. You are on <span>TIER {currentTierIndex + 1}</span>.
        <br />
      Current list price: <span>${tiers[currentTierIndex].price}</span>.
       List <span>{tiers[currentTierIndex + 1].amount[0] - bracket - 1}</span> more dresses to move to the next discount tier!
      </Footer>
    </Column>
  );
}

export default PackageChart;