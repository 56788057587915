import React from 'react';
import Item from '../../../Item';
import Column from '../../../Column';

function FittingRoom({ accountData, products = {} }) {
  const { favoriteListings = [] } = accountData || {};
  const items = favoriteListings.map((id) => products[id]).filter((exists) => !!exists);
  return (
    <Column style={{ alignItems: 'center', margin: '30px 0' }}>
      {
        items.map((item_data, index) => (
          <Item favorite={favoriteListings.includes(item_data.__id)} key={index} {...item_data} />
        ))
      }
    </Column>
  );
}

export default FittingRoom;