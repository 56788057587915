import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImageUpload from '../../../../ImageUpload';
import { updateAccountData } from '../../../../../actions/userActions';
import {
  Container, Summary, NameContainer, Name, Details, MenuItem,
  MemberType, Edit,
} from './styled';
import EditIcon from './edit_icon.svg';
import { getPackageNameFromType } from './helpers';

class ProfileSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = { updatePackage: true };
  }
  render() {
    const {
      updatePage, profilePage, accountData,
      updateAccountData,
    } = this.props;
    const {
      profile, packageType, sellerId,
    } = accountData || {};
    const {
      avatar, firstName, lastName,
    } = profile || {};
    return (
      <Container>
        <img src={avatar} />
        <Summary>
          <NameContainer>
            <Name>
              <div>{firstName} {lastName} {sellerId}</div>
              <ImageUpload style={{
                marginRight: 14,
                marginLeft: 'auto',
              }} onChange={(res) => {
                updateAccountData({
                  profile: {
                    ...profile,
                    avatar: res,
                  },
                });
              }}>
                <Edit src={EditIcon} />
              </ImageUpload>
            </Name>
            <MemberType>{getPackageNameFromType(packageType)}</MemberType>
          </NameContainer>
          <Details>
            <div style={{ marginBottom: '0.5625vw' }}>Your Details</div>
            <MenuItem onClick={() => updatePage('profile')} active={profilePage === 'profile'}>
              <div>Profile</div>
            </MenuItem>
            <MenuItem onClick={() => updatePage('notifications')} active={profilePage === 'notifications'}>
              <div>Notifications</div>
            </MenuItem>
            <MenuItem onClick={() => updatePage('password')} active={profilePage === 'password'}>
              <div>Password</div>
            </MenuItem>
          </Details>
        </Summary>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.userReducer });

const mapDispatchToProps = { updateAccountData };


export default connect(mapStateToProps, mapDispatchToProps)(ProfileSidebar);
