import React, { useEffect, useState } from 'react';
import H3 from '../../../../Text/H3';
import BodyText from '../../../../Text/Body';
import H4 from '../../../../Text/H4';
import Checkbox from '../../../../Checkbox';
import Divider from '../../../../Divider';
import Button from '../../../../Button1';
import Card from '../../../../Card';
import { Body } from './styled';

function Notifications({ accountData, updateAccountData }) {
  const { settings } = accountData || {};
  const [settingData, setSettingData] = useState({ ...settings });

  useEffect(() => {
    setSettingData({ ...settings });
  }, [settings]);

  console.log('settingData', settingData);

  return (
    <Card style={{ margin: 15 }}>
      <H3 style={{ marginBottom: 7 }}>Notifications</H3>
      <BodyText style={{ marginBottom: 15 }}>Receive notifications, requests to write a review, pricing notices, and other reminders related to your activities on So Bridely.</BodyText>
      <Divider style={{ width: 'calc(100% + 40px)', transform: 'translateX(-20px)' }} />
      <Body>
        <H4 style={{ marginBottom: 14 }}>Promotions</H4>
        <H4 style={{ marginBottom: 14 }}>Messages</H4>
        <Checkbox style={{ marginBottom: 10 }} label="Email" active={settingData.promotionEmailNotifications} update={(promotionEmailNotifications) => setSettingData({
          ...settingData,
          promotionEmailNotifications,
        })}/>
        <Checkbox style={{ marginBottom: 10 }} label="Email" active={settingData.messageEmailNotifications} update={(messageEmailNotifications) => setSettingData({
          ...settingData,
          messageEmailNotifications,
        })}/>
        <Checkbox label="Push Notifications" active={settingData.promotionPushNotifications} update={(promotionPushNotifications) => setSettingData({
          ...settingData,
          promotionPushNotifications,
        })}/>
        <Checkbox label="Push Notifications" active={settingData.messagePushNotifications} update={(messagePushNotifications) => setSettingData({
          ...settingData,
          messagePushNotifications,
        })}/>
      </Body>
      <Button style={{ width: 228, marginTop: 20 }} onClick={() => updateAccountData({
        settings: {
          messageEmailNotifications: settingData.messageEmailNotifications,
          messagePushNotifications: settingData.messagePushNotifications,
          promotionEmailNotifications: settingData.promotionEmailNotifications,
          promotionPushNotifications: settingData.promotionPushNotifications,
        },
      })}>Save</Button>
    </Card>
  );
}

export default Notifications;