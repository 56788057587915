import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

export const ItemContainer = styled.div`
  position: relative;
  height: 80px;
  display: flex;
  padding: 7px;
  align-items: center;
  border-bottom: 1px solid #E6EAEE;
  cursor: pointer;
  background-color: ${props => props.active ? '#FEFAFA' : 'white'};
`;

export const ProfileImage = styled.img`
  flex-shrink: 0;
  margin-right: 10px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
`;

export const Name = styled.div`
  font-family: sofia-pro, sans-serif; 
  font-weight: 700;
  font-size: 14px;
  color: #354052;
  margin-bottom: 6px;
`;

export const Time = styled.div`
  position: absolute;
  opacity: 0.3;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #354052;
  text-align: right;
  top: 7px;
  right: 24px;
`;

export const Indicator = styled.div`
  background-color: #FE95A2;
  width: 4px;
  height: 100%;
  position: absolute;
  left: -1px;
  top: 0;
`;

export const Message = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #AAB2C0;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 188px;
`;