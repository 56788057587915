import React, {
  useState, useCallback, useContext,
} from 'react';
import { connect } from 'react-redux';
import ThemedInput from '../../ThemedInput';
import Button1 from '../../Button1';
import Button2 from '../../Button2';
import TextArea from '../../TextArea';
import PhotoUploads from '../../PhotoUploads';
import { TAG_SIZE_OPTIONS, STREET_SIZE_OPTIONS } from '../../../constants';
import { deleteListing } from '../../../actions/userActions';
import ThemedDropdown from '../../ThemedDropdown';
import MultiSelectDropdown from '../../MultiSelectDropdown';
import ThemedDataList from '../../ThemedDataList';
import PhotoUpload from '../../PhotoUpload';
import { AppContext } from '../../AppProvider';
import {
  Container, Heading,
  Title, Close, Section,
  SectionHeading, Footer,
  InputsSection,
} from './styled.js';
import CloseIcon from './close_icon.svg';
const detailSections = [
  {
    heading: 'Share with us your story about your dress',
    id: 'story',
  },
  {
    heading: 'Describe the alterations to the gown',
    id: 'alterations',
  },
  {
    heading: 'Dry cleaning preservation history',
    id: 'history',
  },
];

function ListingForm({
  onSubmit, onClose, title,
  buttonText = 'Create', amountOfPictures,
  deleteListing, ...listingData
}) {
  const {
    state: {
      colorTypes, designerTypes, conditionTypes,
      materialTypes, necklineTypes, sleeveTypes, silhouetteTypes,
    },
  } = useContext(AppContext);
  const sections = [
    {
      heading: 'Give us the exact details',
      forms: [
        [
          {
            label: 'Designer', id: 'designer', options: designerTypes,list:true,
          },
          { label: 'Style Name / Number', id: 'name' }],
        [{
          label: 'What condition is it in?', id: 'condition', options: conditionTypes,
        }],
        [{
          options:TAG_SIZE_OPTIONS,
          type: 'number', pattern: '\\d*', label: 'Label Size', id: 'tagSize',
        }, {
          options:STREET_SIZE_OPTIONS,
          type: 'number', pattern: '\\d*', label: 'Street Size', id: 'streetSize',
        }],
        [{
          type: 'number', pattern: '\\d*', label: 'Bust Size (in)', id: 'bustSize',
        }, {
          type: 'number', pattern: '\\d*', label: 'Waist Size (in)', id: 'waistSize',
        }, {
          type: 'number', pattern: '\\d*', label: 'Hip Size (in)', id: 'hipSize',
        }],
        [{
          options: materialTypes, label: 'Material', id: 'material', list:true, multiple: true,
        },
        {
          options:colorTypes, label: 'Dress Color', id: 'color',list:true,
        }],
      ],
    },
    {
      heading: 'Style',
      forms: [
        [{
          options: necklineTypes, label: 'Neckline', id: 'neckline',list:true,
        }],
        [{
          options: sleeveTypes, label: 'Sleeve Style', id: 'sleeve', list:true,
        }, {
          options: silhouetteTypes, label: 'Silhouette', id: 'silhouette',list:true,
        }],
      ],
    },
    {
      heading: 'Price and Age',
      forms: [
        [{
          type: 'number', pattern: '\\d*', label: 'Year Purchased', id: 'yearPurchased',
        }, {
          type: 'amount', label: 'Retail Price', id: 'price',
        }, {
          type: 'amount', label: 'Selling Price', id: 'salePrice',
        }],
      ],
    },
  ];
  const [bustSize, waistSize, hipSize] = listingData?.dimensions || [];
  const [form, setForm] = useState({
    photos: Array(amountOfPictures).fill(0), ...listingData,bustSize, waistSize, hipSize,
  });
  const submit = useCallback(() => {
    form.dimensions = [form.bustSize, form.waistSize, form.hipSize];
    onSubmit(form);
  }, [form, onSubmit]);
  return (
    <Container onSubmit={(e) => {
      e.preventDefault();
      submit();
      return false;
    }}
    >
      <Heading>
        <Title>{title}</Title>
        <Close onClick={onClose} src={CloseIcon} />
      </Heading>
      {
        sections.map(({ heading, forms }) => (
          <Section key={heading}>
            <SectionHeading>* {heading}:</SectionHeading>
            {
              forms.map((row, index) => (
                <InputsSection key={index}>
                  {
                    row.map(({
                      type, label, id, pattern, options, list, multiple,
                    })=> (options && list && multiple) ? (<MultiSelectDropdown options={options} value={form[id]} type={type} pattern={pattern} required={true} update={(value) => setForm({
                      ...form,
                      [id]: value,
                    })} key={label} label={label}
                    />) :
                      (options && list) ? (<ThemedDataList options={options} value={form[id]} type={type} pattern={pattern} required={true} update={(value) => setForm({
                        ...form,
                        [id]: value,
                      })} key={label} label={label}
                      />) :
                        options ? (<ThemedDropdown options={options} value={form[id]} type={type} pattern={pattern} required={true} update={(value) => setForm({
                          ...form,
                          [id]: value,
                        })} key={label} label={label}
                        />) : (
                          <ThemedInput value={form[id]} type={type} pattern={pattern} required={true} update={(value) => setForm({
                            ...form,
                            [id]: value,
                          })} key={label} label={label}
                          />))
                  }
                </InputsSection>
              ))
            }
          </Section>
        ))
      }
      <Section>
        <SectionHeading>* Upload up to {amountOfPictures} photos:</SectionHeading>
        <PhotoUploads amount={amountOfPictures} update={(photos) => setForm({
          ...form,
          photos,
        })} photos={form.photos}
        />
      </Section>
      {
        detailSections.map(({ heading, id }, index) => (
          <Section last={index === detailSections.length - 1} key={id}>
            <SectionHeading>{heading}</SectionHeading>
            <TextArea value={form[id]} update={(value) => setForm({
              ...form,
              [id]: value,
            })} style={{
              backgroundColor: '#FEFAFA', border: '1px solid #E1CFCD', height: 81,
            }}
            />
          </Section>
        ))
      }
      <Section>
        <SectionHeading>Upload a photo of your receipt / Proof of Authenticity:</SectionHeading>
        <PhotoUpload update={(receipt) => setForm({
          ...form,
          receipt,
        })} photo={form.receipt}
        />
      </Section>
      <Footer>
        <Button2 style={{ marginTop: 20 }} onClick={onClose}>Cancel</Button2>
        <Button1 type="submit" style={{ marginTop: 20 }} >{buttonText}</Button1>
        {listingData && listingData.__id && <Button1 style={{ marginTop: 20 }} color="#D17F8A" onClick={() => {
          const res = confirm('Are you sure you want to delete this listing?');

          if (res) {
            deleteListing(listingData.__id);
            onClose();
          }
        }}
        >Delete</Button1>}
      </Footer>
    </Container>
  );
}

const mapDispatchToProps = { deleteListing };

export default connect(null, mapDispatchToProps)(ListingForm);