import React, { useState } from 'react';
import * as utils from '../../../../../../helpers/Format';
import { Container } from './styled';
import SearchIcon from './SearchIcon.svg';

function SearchBar({ messages, update }) {
  const [searchValue, setSearchValue] = useState(null);

  function search({ target: { value: _searchValue } }) {
    const searchResults = utils.searchForMessage(_searchValue, messages);
    setSearchValue(_searchValue);
    update(searchResults);
  }
  return (
    <Container>
      <img src={SearchIcon} />
      <input
        onChange={search}
        value={searchValue} />
    </Container>
  );
}

export default SearchBar;
