import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styled';

function Utility({ children, ...styleProps }) {
  return <Container {...styleProps}>{children}</Container>;
}

Utility.propTypes = {
  children: PropTypes.array.isRequired,
  type: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  bold: PropTypes.bool,
  right: PropTypes.bool,
  left: PropTypes.bool,
};

export default Utility;
export const UtilityStyles = Container;
