import React from 'react';
import moment from 'moment';
import {
  ScrollContainer, Container, ItemContainer, Name, LastMessage, Time, Indicator,
} from './styled';
import SearchBar from './SearchBar';

function getLastMessage(thread) {
  const lastMesssage = thread.slice(-1)[0] || {};
  return lastMesssage?.message;
}

function MessageList({
  messages, currentMessageId, selectMessage,
  searchResults, setSearch,
}) {
  return (
    <Container>
      <SearchBar update={setSearch} messages={messages} />
      <ScrollContainer>
        {
          Object.values(searchResults).map(({
            name, avatar, thread,
          }, index) => {
            if (!thread || !thread.length) {
              return <div />;
            }

            const active = thread.find(({ id }) => id === currentMessageId);
            return (
              <ItemContainer onClick={() => selectMessage(thread.slice(-1)[0])} active={active} key={index}>
                <img src={avatar} />
                <div>
                  <Name>{name}</Name>
                  <LastMessage>{getLastMessage(thread)}</LastMessage>
                </div>
                <Time>{moment(thread.slice(-1)[0].date).fromNow()}</Time>
                {active && <Indicator />}
              </ItemContainer>
            );
          })
        }
      </ScrollContainer>
    </Container>
  );
}

export default MessageList;
