import React from 'react';
import moment from 'moment';
import { LineChart } from 'react-chartkick';
import { Container } from './styled.js';
import 'chart.js';

function DressAnalytics({ views }) {
  const data = views.map(({ date }, index) => ([moment(date).format('YYYY-MM-DD'), index + 1]));
  return (
    <Container>
      <LineChart
        colors={['#E1CFCD']}
        library={{
          title: {
            fontSize: 10, display: true, padding: 0,
            position:'bottom', text: 'ALL TIME VIEWS',
            fontFamily:'sofia-pro', fontWeight: 500, fontColor: '#124C56',
          },
          layout: { padding: 20 },
          elements: {
            line: {
              tension: .1, borderColor: '#E1CFCD', borderWidth: 1,
            },
            point: { radius: 1 },
          },
          lineTension: 0,
          scales:{ yAxes: [{ ticks: { stepSize: 1, display: false } }] },
        }}
        height={100}
        width='90%'
        data={data} />
    </Container>
  );
}

export default DressAnalytics;