import React from 'react';
import ListingForm from '../../../ListingForm';
import { Container } from './styled';

function ListingDetails({
  close, onSubmit, packageType,
  accountType, buttonText,
  title='Create New Listing',
  ...listing
}) {
  const amountOfPictures = packageType === 1 ? 10 : accountType === 0 ? 10 : 5;
  return (
    <Container>
      <ListingForm
        amountOfPictures={amountOfPictures}
        {...listing}
        onClose={close}
        title={title}
        buttonText={buttonText}
        onSubmit={onSubmit}
      />
    </Container>
  );
}

export default ListingDetails;
