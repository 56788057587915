import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 7.313vw;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0 2px 6px 0 rgba(69,73,91,0.08);
  width: 100%;
  max-width: 300px;
  cursor: pointer;
  @media (max-width: 500px) {
    margin-bottom: 30px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const ItemHeader = styled.div`
  height: 50px;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const New = styled.div`
  width: 80px;
  background-color: #7174A8;
  height: 100%;
  align-self: flex-start;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

export const ItemTitle = styled.div`
font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 22px;
  color: #124C56;
  letter-spacing: 0;
  text-align: center;
  margin: 0 auto;
`;

export const ItemPicture = styled.div`
background-color: #EDE7E3;
display: flex;
justify-content: center;
height: 100%;
  img {
    object-fit: contain;
    width: 100%;
    height: 308px;
    max-height: 308px;
    margin: 0 auto;
  }
`;

export const ItemDetailsContainer = styled.div`
  background-color: white;
  height: 68px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 14px;
`;

export const ItemPrice = styled.div`
  text-decoration: line-through;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #6B969D;
  letter-spacing: 0;
  text-align: right;
`;

export const ItemSalePrice = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #124C56;
  letter-spacing: 0;
  text-align: right;
`;

export const ItemListNumber = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #6B969D;
  letter-spacing: 0;
  line-height: 24px;
`;

export const ItemCondition = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #6B969D;
  letter-spacing: 0;
  line-height: 24px;
`;

export const ItemFooter = styled.div`
  display: flex;
  height: 57px;
  justify-content:space-between;
  align-items: center;
  background-color: #FBFCFE;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #E1CFCD;
  padding: 0 15px;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  letter-spacing: 0;
`;

export const LoadMoreButton = styled.div`
  margin: 0 auto;
  margin-top: 6.125vw;
  background: #FE95A2;
  font-size: 0.8125vw;
  color: white;
  letter-spacing: 0.06250vww;
  line-height: 1.125vw;
  border-radius: 0.6250vw;
  height: 2.875vw;
  width: 18.25vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
`;