import React, { useState } from 'react';
import moment from 'moment';
import Row from '../../../../Row';
import Button1 from '../../../../Button1';
import { sendMessage } from '../../../../../helpers/API';
import {
  Container, Name, Thread, Message, MessageInput, Send, Time, Input,
} from './styled';


function CurrentMessages({
  name, uid, thread = [], avatar, accountType, profile, user,
}) {
  const [text, setText] = useState(null);
  return (
    <Container>
      <Name>{name}</Name>
      <Thread>
        {thread.sort((a, b) => a.date - b.date).map(({
          message, date, from, component,
        }, index) => (
          <Message key={index}>
            <img src={from === user.uid ? profile.avatar : avatar} />
            <div>
              <div>{from === user.uid ? `${profile.firstName} ${profile.lastName}` : name}</div>
              <div>{message}</div>
              {component}
            </div>
            <Time>{moment(date).fromNow()}</Time>
          </Message>
        ))}
      </Thread>
      <MessageInput>
        <Input value={text} onChange={({ target: { value: text } }) => setText(text)} />
        <Send onClick={() => {
          if (text) {
            sendMessage(uid, accountType, text);
            setText('');
          }
        }}>Send</Send>
      </MessageInput>
    </Container>
  );
}

export default CurrentMessages;
