import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  margin: 40px;
`;

export const Heading = styled.div`
  padding-left: 40px;
  margin-top: 41px;
  margin-bottom: 24px;
`;

export const Title = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #124C56;
  line-height: 28px;
`;

export const SubTitle = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #6B969D;
  line-height: 22px;
`;

export const PackageContainer = styled.div`
  margin: 40px;
  background: #FFFFFF;
  border: 1px solid #7DBEC8;
  height: 322px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const PackageSection = styled.div`

`;