import React from 'react';
import Image from '../ImageBasic';
import ArrowSVG from './arrow.svg';
import { Container } from './styled.js';

function Button1({
  children, color = '#7DBEC8', ...other
}) {
  return (
    <Container color={color} {...other}>
      <div style={{ marginLeft: 20 }}>
        {children}
      </div>
      <Image style={{ margin: '0 20px' }} src={ArrowSVG} />
    </Container>
  );
}

export default Button1;