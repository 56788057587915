import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background: white;
  width: 22.81vw;
  flex-direction: column;
  border-right: 2px solid #EAEDF3;
  max-height: 90.00vw;
`;
export const ActiveIndicator = styled.div`
  position: absolute;
  height: 100%;
  width: 3px;
  background-color: #6B969D;
  top:0;
  left: 0;
`;
export const Heading = styled.div`
  height: 3.750vw;
  margin: 0 1.875vw;
  margin-top: 1.875vw;
  padding-bottom: 1.875vw;
  display: flex;
  border-bottom: 1px solid #EAEDF3;
  align-items: center;
  img {
    width: 3.750vw;
    height: 3.750vw;
    border-radius: calc(3.750vw / 2);
    object-fit: contain;
  }
`;
export const Username = styled.div`
  margin-left: 1.250vw;
  > div:nth-child(1) {
    font-family: sofia-pro, sans-serif;
  font-weight: 700;
    font-size: 1.000vw;
    color: #3E3F42;
    line-height: 24px;
  }
  > div:nth-child(2) {
    font-family: sofia-pro, sans-serif;
  font-weight: 700;
    font-size: 0.8750vw;
    color: #9EA0A5;
    line-height: 24px;
  }
`;
export const MainMenu = styled.div`
  margin-left: 1.875vw;
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 0.7500vw;
  color: #9EA0A5;
  line-height: 18px;
  text-transform: uppercase;
  margin-top: 1.625vw;
  margin-bottom: 0.9375vw;
`;
export const MenuItem = styled.div`
  position: relative;
  cursor: pointer;
  background-color: ${props => props.active ? '#E5F2F4' : '#FFFFFF'};
  height: 2.875vw;
  padding-left: 1.875vw;
  display: flex;
  align-items: center;
  > div {
    color: #6B969D;
    font-family: sofia-pro, sans-serif;
  font-weight: 700;
    font-size: 0.8750vw;
    line-height: 22px;
  }
  img {
    height: 1.250vw;
    width: 1.250vw;
    object-fit: contain;
    margin-right: 1.250vw;
  }
`;
