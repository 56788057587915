import React, { useState } from 'react';
import { getPackageNameFromType } from '../../../Desktop/Dashboard/Profile/ProfileSidebar/helpers.js';
import {
  seller_pages, user_pages, seller_pages_boutique,
} from '../../../Desktop/Dashboard/SideBar';
import {
  Container, Heading, Title, Arrow,
  ProfileHeading, ProfilePicture, ProfileDetails,
  Name, ProfileType, MenuBody, MenuTitle,
  MenuItem, ActiveIndicator, MenuItemName, MenuIcon,
} from './styled.js';
import ArrowIcon from './ArrowIcon.svg';

function ProfileBar({
  accountData, pageIndex, changePage, accountType,
}) {
  const {
    packageType, profile: {
      avatar, firstName, lastName,
    },
  } = accountData || {};
  const [expanded, setExpanded] = useState(false);
  const packageName = getPackageNameFromType(packageType);
  return (
    <Container>
      <Heading onClick={() => setExpanded(!expanded)}>
        <Title>Dashboard Menu</Title>
        <Arrow expanded={expanded} src={ArrowIcon}/>
      </Heading>
      <MenuBody expanded={expanded}>
        <ProfileHeading>
          <ProfilePicture src={avatar} />
          <ProfileDetails>
            <Name>{firstName}&nbsp;{lastName}</Name>
            <ProfileType>{packageName}</ProfileType>
          </ProfileDetails>
        </ProfileHeading>
        <MenuTitle>Main Menu</MenuTitle>
        {
          (accountType === 0 ? seller_pages_boutique : accountType === 1 ? seller_pages : user_pages).map(({
            name, id, img, imgActive,
          }, index) => (
            <MenuItem active={pageIndex === id} onClick={() => {
              changePage(id);
              setExpanded(!expanded);
            }} key={index}>
              {pageIndex === id && <ActiveIndicator />}
              <MenuIcon src={pageIndex === id ? imgActive : img} />
              <MenuItemName active={pageIndex === id}>{name}</MenuItemName>
            </MenuItem>
          ))
        }
      </MenuBody>
    </Container>
  );
}

export default ProfileBar;
