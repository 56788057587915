import styled from 'styled-components';

export const Container = styled.div`
  font-size: 28px;
  line-height: 32px;
  color: ${props => (props.color ? props.color : '#3E3F42')};
  font-weight: ${props =>
    props.bold
      ? 700
      : props.semibold
        ? 600
        : 700};
    font-family: sofia-pro, sans-serif;
  text-align: ${props =>
    props.right ? 'right' : props.left ? 'left' : undefined};
`;