import React from 'react';
import {
  Container, OrderNumber, Total, PaymentType,
} from './styled';

function OrderDetail({
  __id,
  amount,
  paymentType = 'Credit Card',
}) {
  return (
    <Container>
      <OrderNumber>#{__id}</OrderNumber>
      {/* <Date>{moment(date).format('MM/DD/YYYY')}</Date> */}
      <div>
        <Total>${parseFloat(amount / 100).toFixed(2)}</Total>
        <PaymentType>{paymentType}</PaymentType>
      </div>
    </Container>
  );
}

export default OrderDetail;

