import React, { useState } from 'react';
import { connect } from 'react-redux';
import Utility from '../../../Text/Utility';
import H2 from '../../../Text/H2';
import Body from '../../../Text/Body';
import { getPackageNameFromType } from '../../../Desktop/Dashboard/Profile/ProfileSidebar/helpers';
import { updatePackage } from '../../../../actions/userActions';
import Divider from '../../../Divider';
import Card from '../../../Card';
import PackageSelection from './PackageSelection';
import Boosts from './Boosts';
import Cancel from './Cancel';
import {
  Container, NameDetails, ProfilePicture,
  ProfileNavigation, MenuItemContainer,
} from './styled.js';

function MyPackage({
  accountData , updatePackage, listings,
}) {
  const {
    packageType,
    accountType,
    profile:{
      avatar, firstName, lastName,
    },
  } = accountData || {};
  const [activeSection, setActiveSection] = useState(0);
  return (
    <Container>
      <Card style={{ marginBottom: 30, alignItems: 'flex-start' }}>
        <ProfilePicture src={avatar}/>
        <NameDetails>
          <H2>{firstName}&nbsp;{lastName}</H2>
          <Body>{getPackageNameFromType(accountType)}</Body>
        </NameDetails>
        <Divider />
        <ProfileNavigation>
          <Utility style={{ marginBottom: 9 }}>Your Details</Utility>
          <MenuItemContainer active={activeSection === 0} onClick={() => setActiveSection(0)}>Package Details</MenuItemContainer>
          {packageType === 1 && <MenuItemContainer active={activeSection === 1} onClick={() => setActiveSection(1)}>Boosts</MenuItemContainer>}
          <MenuItemContainer active={activeSection === 2} onClick={() => setActiveSection(2)}>Cancel</MenuItemContainer>
        </ProfileNavigation>
      </Card>
      <PackageSelection accountData={accountData} updatePackage={updatePackage} />
      {packageType === 1 && <Boosts listings={listings}/>}
      <Cancel />
    </Container>
  );
}

const mapDispatchToProps = { updatePackage };

export default connect(null, mapDispatchToProps)(MyPackage);