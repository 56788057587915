import styled from 'styled-components';

export const Container = styled.div`
  height: fit-content;
  width: 45.63vw;
  margin-top: 1.875vw;
  background: #FFFFFF;
  border: 1px solid #EAEDF3;
  border-radius: 0.2500vw;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.04);
  margin-left: 3.375vw;
  display: flex;
  flex-direction: column;
`;

export const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: #EAEDF3;
`;

export const Heading = styled.div`
  padding-left: 1.875vw;
  margin-top: 1.438vw;
  margin-bottom: 1.500vw;
  > div:nth-child(1) {
    font-family: sofia-pro, sans-serif;
  font-weight: 700;
    font-size: 1.125vw;
    color: #3E3F42;
    line-height: 28px;
  }
  > div:nth-child(2) {
    font-family: sofia-pro, sans-serif;
  font-weight: 700;
    font-size: 0.8750vw;
    color: #9EA0A5;
    line-height: 22px;
    margin-top: 0.2500vw;
    width: 41.88vw;
  }
`;

export const Body = styled.div`
  display: grid;
  grid-template-columns: 20.63vw 20.63vw;
  margin: 0 auto;
  grid-gap: 1.000vw 0.6250vw;
  margin-top: 1.500vw;
  margin-bottom: 2.750vw;
`;

export const SaveButton = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
  line-height: 22px;
  background: #FE95A2;
  border: 1px solid #FE95A2;
  border-radius: 0.2500vw;
  display: flex;
  justify-content:center;
  align-items: center;
  box-shadow: 0 1px 1px 0 rgba(19,31,21,0.10), inset 0 2px 0 0 rgba(255,255,255,0.06);
  width: 7.750vw;
  height: 2.375vw;
  margin-top: 1.250vw;
  margin-left: 1.875vw;
  cursor: pointer;
  margin-bottom: 1.563vw;
`;