import styled from 'styled-components';

export const Container = styled.div`
  background-color: #FCFCFC;
  border: 1px solid #EAEDF3;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.04);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Body = styled.div`
  width: calc(100% - 30px);
  margin: 15px 0;
  position: relative;
`;