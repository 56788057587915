import React, { Component } from 'react';
import { Container, Heading } from './styled';
import ProfileSidebar from './ProfileSidebar';
import Details from './Details';
import Boosts from './Boosts';
import Cancel from './Cancel';

class MyPackage extends Component {
  constructor(props) {
    super(props);
    this.state = { section: 'details' };
  }
  render() {
    let content = <div></div>;
    const { products, accountData } = this.props || {};
    const { listings = [] } = accountData || {};
    const items = listings.map((id) => products[id]);

    switch (this.state.section) {
    case 'details':
      content = <Details {...this.props}/>;
      break;
    case 'boosts':
      content = <Boosts listings={items} />;
      break;
    case 'cancel':
      content = <Cancel />;
      break;
    default:
      break;
    }
    return (
      <Container>
        <Heading>My Package</Heading>
        <div style={{ display: 'flex' }}>
          <ProfileSidebar
            updatePage={(section) => this.setState({ section })}
            profilePage={this.state.section} {...this.props} />
          {content}
        </div>
      </Container>
    );
  }
}

export default MyPackage;