import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background: white;
  flex-direction: column;
`;

export const Heading = styled.div`
  display: grid;
  background: #FFFFFF;
  box-shadow: 0 20px 20px 0 rgba(0,0,0,0.05);
  height: 69px;
  grid-template-columns: 1fr 1fr;
  padding-left: 30px;
`;

export const Orders = styled.div`
  margin-top: 30px;
  > div:last-child {
    border-bottom: 0;
  }
`;

export const Label = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #AAAAAA;
  letter-spacing: 1.4px;
  line-height: 18px;
  align-self: center;
  cursor: pointer;
`;