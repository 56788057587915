import React from 'react';
import styled from 'styled-components';
import Utility from '../Text/Utility';

const InputComponent = styled.input`
  justify-content: center;
  align-items: center;
  display: flex;
  background: #FFFFFF;
  border: 1px solid #E4E5EC;
  box-shadow: 0 2px 1px 0 rgba(0,0,0,0.03);
  min-height: 40px;
  width: calc(100% - 10px);
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 0.8750vw;
  color: #898EA2;
  letter-spacing: 0;
  line-height: 22px;
  padding-left: 10px;
  font-size: 12px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

function Input({
  label = '', value, update, inputProps,
  disabled, ...other
}) {
  return (
    <Container {...other}>
      <Utility style={{ marginBottom: 7, marginTop: 3 }}>{label}</Utility>
      <InputComponent disabled={disabled} {...inputProps} name={label.toLowerCase().trim()} value={value} onChange={(e) => update(e.target.value)} placeholder={label} />
    </Container>
  );
}

export default Input;
