import React, { useState } from 'react';
import H3 from '../../../../Text/H3';
import BodyText from '../../../../Text/Body';
import Divider from '../../../../Divider';
import Button from '../../../../Button1';
import Card from '../../../../Card';
import Packages from '../../../SellerCreateAccount/Page3/Packages';

function PackageSelection({ accountData, updatePackage }) {
  const { packageType } = accountData || {};
  const [_package, setPackage] = useState(packageType);
  return (
    <Card style={{ margin: 15 }}>
      <H3 style={{ marginBottom: 7 }}>Upgrade My Package</H3>
      <BodyText style={{ marginBottom: 15 }}>Choose the package that best fits your needs</BodyText>
      <Divider style={{ width: 'calc(100% + 40px)', transform: 'translateX(-20px)' }} />
      <Packages
        disabled={packageType === 1}
        value={_package}
        update={setPackage}/>
      <Button style={{ width: 228, marginTop: 20 }} onClick={() => updatePackage(_package)}>Confirm &amp; Pay</Button>
    </Card>
  );
}


// const PageWrapper = (props) => (
//   <Elements stripe={stripePromise}>
//     <PackageSelection {...props} />
//   </Elements>
// );


export default PackageSelection;