import styled from 'styled-components';

export const Container = styled.div`
  z-index: 1;
  position: relative;
`;

export const Heading = styled.div`
  height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 30px;
  z-index: 2;
  background-color: white;
`;

export const Title = styled.div`
  font-family: sofia-pro, sans-serif; 
  font-weight: 700;
  font-size: 18px;
  color: #3E3F42;
  line-height: 28px;
`;

export const Arrow = styled.img`
  margin-bottom: 3px;
  margin-left: 10px;
  transform: ${props => props.expanded ? 'rotate(-180deg)' : 'rotate(0deg)'};
`;

export const ProfileHeading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #EAEDF3;
  border-top: 1px solid #EAEDF3;
  padding: 20px 0;
  margin: 0 30px;
`;

export const ProfilePicture = styled.img`
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin-right: 20px;
`;

export const ProfileDetails = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

export const Name = styled.div`
  font-family: sofia-pro, sans-serif; 
  font-weight: 700;
  font-size: 16px;
  color: #3E3F42;
  line-height: 24px;
`;

export const ProfileType = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #9EA0A5;
  line-height: 22px;
`;

export const MenuBody = styled.div`
  transform: ${props => props.expanded ? 'translate(0, 0)' : 'translate(0, -120%)'};
  opacity: ${props => props.expanded ? '1' : '0'};
  position: absolute;
  transition: ${props => props.expanded ? 'transform .25s ease-in-out, opacity .25s ease-in-out' : 'transform .3s ease-in-out, opacity 0s .3s'};
  background-color: white;
  z-index: -1;
  width: 100%;
`;

export const MenuTitle = styled.div`
  font-family: sofia-pro, sans-serif; 
  font-weight: 700;
  font-size: 12px;
  color: #9EA0A5;
  line-height: 18px;
  margin-left: 30px;
  margin-top: 25px;
  margin-bottom: 15px;
  text-transform: uppercase;
`;

export const ActiveIndicator = styled.div`
  position: absolute;
  height: 100%;
  width: 3px;
  background-color: #6B969D;
  top:0;
  left: 0;
`;

export const MenuItem = styled.div`
  position: relative;
  background-color: ${props => props.active ? '#E5F2F4' : '#FFFFFF'};
  height: 46px;
  padding-left: 30px;
  display: flex;
  align-items: center;
`;

export const MenuIcon = styled.img`
  height: 20px;
  width: 20px;
  object-fit: contain;
  margin-right: 20px;
`;

export const MenuItemName = styled.div`
  color: #6B969D;
  font-family: sofia-pro, sans-serif; 
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
`;