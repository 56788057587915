import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  input {
    height: 2.375vw;
    font-family: sofia-pro, sans-serif;
  font-weight: 700;
    font-size: 14px;
    color: #3E3F42;
    line-height: 22px;
    background: #FFFFFF;
    border: 1px solid #E2E5ED;
    box-shadow: inset 0 1px 2px 0 rgba(102,113,123,0.21);
    padding-left: 1.000vw;
    border-radius: 0.2500vw;
  }
`;

export const Label = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 0.7500vw;
  color: #9EA0A5;
  line-height: 18px;
  margin-bottom: 0.4375vw;
`;
