import React from 'react';
import Regular from '../../../../Text/Regular';
import Column from '../../../../Column';
import Row from '../../../../Row';
import Image from '../../../../ImageBasic';
import { formatAmount } from '../../../../../helpers/Format';
import {
  Heading, Title, SubTitle,
} from './styled';

function Boosts({ listings }) {
  const boosted = listings.filter(({ boostDate }) => !!boostDate);
  return (
    <Column style={{
      margin: 30, backgroundColor: 'white', justifyContent: 'flex-start',
      width: '100%',
    }}>
      <Heading>
        <Title>My Boosts</Title>
        <SubTitle>These are the listings you currently have boosted</SubTitle>
      </Heading>
      {
        boosted.map(({
          photos:[photo], name, salePrice,
        }, index) => (
          <>
            <Row key={index} style={{
              width: 'calc(100% - 80px)', marginTop: 24, padding: '30px 40px',
              borderTop: '1px solid #EAEDF3', alignItems: 'center',
            }}>
              <Image style={{
                height: 88, width: 'auto', marginRight: 13,
              }} src={photo} />
              <Regular>{name}</Regular>
              <Regular style={{ marginLeft: 'auto' }}>${formatAmount(salePrice)}</Regular>
            </Row>
          </>
        ))
      }
    </Column>
  );
}

export default Boosts;