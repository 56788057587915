import React from 'react';
import { Container, Heading } from './styled';
import OrderForm from './OrderForm';

function OrderHistory(){
  return (
    <Container>
      <Heading>Order History</Heading>
      <OrderForm />
    </Container>
  );
}

export default OrderHistory;
