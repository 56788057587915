import React, {
  useState, useEffect, useCallback,
} from 'react';
import { connect } from 'react-redux';
import { updateAccountData } from '../../../../../actions/userActions';
import Checkbox from '../../../../Checkbox';
import {
  Container, Heading, Divider, Body, CheckBoxInputs,
  Label, SaveButton,
} from './styled';
const input_checkboxes = [
  {
    id: 'Promotions',
    checkboxes: [{
      id: 'promotionEmailNotifications',
      name: 'Email',
    }, {
      name: 'Push notifications',
      id: 'promotionPushNotifications',
      info: 'For your mobile or tablet device',
    }],
  },
  {
    id: 'Messages',
    checkboxes: [{
      name: 'Email',
      id: 'messageEmailNotifications',
    }, {
      name: 'Push notifications',
      id: 'messagePushNotifications',
      info: 'For your mobile or tablet device',
    }],
  },
];

function NotificationsSection({ accountData, updateAccountData }) {
  const [notifications, setNotifications] = useState({});

  useEffect(() => {
    const {
      settings: {
        messageEmailNotifications,
        messagePushNotifications,
        promotionPushNotifications,
        promotionEmailNotifications,
      },
    } = accountData || {};
    const notificationsData = {
      messageEmailNotifications,
      messagePushNotifications,
      promotionPushNotifications,
      promotionEmailNotifications,
    };
    setNotifications({ ...notificationsData });
  }, [accountData]);

  const update = useCallback((key, val) => {
    setNotifications({ ...notifications, [key]: val });
  }, [notifications]);

  console.log('notifications', notifications);
  return (
    <Container>
      <Heading>
        <div>Notifications</div>
        <div>Receive notifications requests to write a review. pricing notices, and other reminders related to your acitvies on So Bridely</div>
      </Heading>
      <Divider />
      <Body>
        {input_checkboxes.map(({ id, checkboxes }, index) => (
          <CheckBoxInputs key={`${id}-${index}`}>
            <Label>{id}</Label>
            {
              checkboxes.map(({ id, name }) => (
                <Checkbox
                  style={{ marginBottom: 10 }}
                  update={() => update(id, !notifications[id])}
                  active={notifications[id]}
                  label={name}
                  key={index} />
              ))
            }
          </CheckBoxInputs>
        ))}
      </Body>
      <Divider />
      <SaveButton onClick={() => updateAccountData({ settings: notifications })}>Save</SaveButton>
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state.userReducer });

const mapDispatchToProps = { updateAccountData };


export default connect(mapStateToProps, mapDispatchToProps)(NotificationsSection);
