import styled from 'styled-components';

export const Section = styled.div`
  margin: 35px 44px;
  padding-bottom: 36px;
  border-bottom: ${props => props.last ? 'none' : '1px solid rgba(234,175,183,0.40)'};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-start;
  border-top: 1px solid #EAEDF3;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

export const Container = styled.form`
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #EAEDF3;
  height: 86px;
  width: 100%;
`;

export const Title = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: #3E3F42;
  line-height: 30px;
  margin-left: 30px;
`;

export const Close = styled.img`
  transform: rotate(45deg);
  margin-right: 20px;
  padding: 10px;
  cursor: pointer;
`;

export const SectionHeading = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0;
  margin-bottom: 30px;
`;

export const InputsSection = styled.div`
  display: flex;
  margin-bottom: 30px;
  :last-of-type {
    margin-bottom: 0;
  }
  > div {
    margin-left: 20px;
  }
  > div:first-child {
    margin-left: 0px;
  }
`;