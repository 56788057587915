import React, { useEffect } from 'react';
import { connect } from 'react-redux';
//components
import Layout from '../components/Layout';
import HomeDesktop from '../components/Desktop/Dashboard';
import MobileDesktop from '../components/Mobile/Dashboard';
//styles
import './style.css';
import Loader from '../components/Loader';
//actions
import {
  updatePassword, updateAccountData, acceptOffer, declineOffer,
} from '../actions/userActions';
import { setMetaTags } from '../actions/homeActions';

function Dashboard(props) {
  const { setMetaTags } = props;

  useEffect(() => {
    const description = 'Your Dashboard';
    const image = '';
    const url = window.location.href;
    const title = `SoBridely`;

    setMetaTags({
      description, image, url, title,
    });
  }, [setMetaTags]);

  if (!props.accountData || !Object.keys(props.accountData).length) {
    return <Loader show={true} />;
  }
  return (
    <Layout
      mobile={<MobileDesktop {...props} />}
      desktop={<HomeDesktop {...props}/>} />
  );
}

const mapStateToProps = (state) => ({ ...state.userReducer, ...state.shopReducer });
const mapDispatchToProps = {
  updatePassword, updateAccountData, setMetaTags, acceptOffer, declineOffer,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);