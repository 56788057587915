import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Body = styled.div`
  width: 55.88vw;
  margin-left: 2.125vw;
  margin-top: 1.250vw;
  background: #FFFFFF;
  border: 1px solid #EAEDF3;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.04);
  border-radius: 0.2500vw;
  display: flex;
  flex-direction: column;
`;

export const BodyHeading = styled.div`
  margin-top: 1.438vw;
  font-family: sofia-pro, sans-serif; 
  font-weight: 400;
  font-weight: bold;
  font-size: 1.125vw;
  color: #3E3F42;
  line-height: 28px;
  margin-left: 1.875vw;
`;

export const BodySubHeading = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 0.8750vw;
  color: #9EA0A5;
  line-height: 22px;
  margin-top: 0.2500vw;
  margin-left: 1.875vw;
  margin-bottom: 1.500vw;
`;

export const Heading = styled.div`
  background: #FFFFFF;
  border-bottom: 2px solid #EAEDF3;
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 1.125vw;
  color: #3E3F42;
  line-height: 28px;
  padding-left: 1.875vw;
  display: flex;
  align-items: center;
  height: 4.375vw;
  width: 100%;
`;

export const Divider = styled.div`
  background-color: #EAEDF3;
  width: 100%;
  height: 1px;
`;

export const Items = styled.div`
  grid-gap: 2.438vw;
  display: grid;
  margin: 0 2.750vw;
  grid-template-columns: 1fr 1fr;
  margin-top: 1.688vw;
  margin-bottom: 4.438vw;
`;