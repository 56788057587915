import styled from 'styled-components';

export const Container = styled.button`
  background: ${props => props.color};
  height: 60px;
  width: 226px;
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 1px;
  color: #FFFFFF;
  text-align: center;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  text-transform: uppercase;
  margin: auto;
`;