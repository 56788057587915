import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.div`
  background: #FFFFFF;
  border-bottom: 2px solid #EAEDF3;
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 1.125vw;
  color: #3E3F42;
  line-height: 28px;
  padding-left: 1.875vw;
  display: flex;
  align-items: center;
  height: 4.375vw;
`;
