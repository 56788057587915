import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: ${props => props.flip ? '100%': '1px'};
  width: ${props => props.flip ? '1px': '100%'};
  background-color: #EAEDF3;
`;

function Divider(props) {
  const vertical = typeof(props.vertical) !== 'undefined';
  return (
    <Container flip={vertical} {...props} />
  );
}

export default Divider;