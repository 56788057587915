import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Navigation from '../../../../Text/Navigation';
import { sendMessage } from '../../../../../helpers/API';
import {
  Container, Name, Thread, Message,
  MessageInput, Send, Time, Input,
  ProfileImage, Content, ContentName,
  ContentMessage,
} from './styled';

function CurrentUserMessage({
  name, uid, thread = [],
  avatar, accountType, back,
  user, accountData,
}) {
  const { profile = {} } = accountData || {};
  const [text, setText] = useState(null);

  useEffect(() => {
    if (thread.length) {
      $(`#message-${thread.length - 1}`)[0].scrollIntoView({ behavior:'smooth' });
    } else {
      back();
    }
  }, [back, thread]);
  return (
    <Container>
      <Navigation style={{ margin: 20 }} onClick={back}>Back</Navigation>
      <Name>{name}</Name>
      <Thread>
        {thread.sort((a, b) => a.date - b.date).map(({
          message, date, from,
        }, index) => (
          <Message id={`message-${index}`} key={index}>
            <ProfileImage src={from === user.uid ? profile.avatar : avatar} />
            <Content>
              <ContentName>{from === user.uid ? `${profile.firstName} ${profile.lastName}` : name}</ContentName>
              <ContentMessage>{message}</ContentMessage>
            </Content>
            <Time>{moment(date).fromNow()}</Time>
          </Message>
        ))}
      </Thread>
      <MessageInput>
        <Input value={text} onChange={({ target: { value: text } }) => setText(text)} />
        <Send onClick={() => {
          if (text) {
            setText('');
            sendMessage(uid, accountType, text);
          }
        }}>Send</Send>
      </MessageInput>
    </Container>
  );
}

export default CurrentUserMessage;
