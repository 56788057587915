import styled from 'styled-components';

export const Container = styled.label`
  
`;

export const Input = styled.input`
  cursor: pointer;
  z-index: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  visibility: hidden;
`;