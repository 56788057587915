import React, { Component } from 'react';
import { Container, Heading } from './styled';
import ProfileSidebar from './ProfileSidebar';
import PackageChart from './PackageChart';
import Cancel from './Cancel';

class MyPackage extends Component {
  constructor(props) {
    super(props);
    this.state = { section: 'chart' };
  }
  render() {
    let content = <div></div>;

    switch (this.state.section) {
    case 'chart':
      content = <PackageChart {...this.props}/>;
      break;
    case 'cancel':
      content = <Cancel />;
      break;
    default:
      break;
    }
    return (
      <Container>
        <Heading>My Package</Heading>
        <div style={{ display: 'flex' }}>
          <ProfileSidebar
            updatePage={(section) => this.setState({ section })}
            profilePage={this.state.section} {...this.props} />
          {content}
        </div>
      </Container>
    );
  }
}

export default MyPackage;