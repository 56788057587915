import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styled';

function H2({ children, ...styleProps }) {
  return <Container {...styleProps}>{children}</Container>;
}

H2.propTypes = {
  children: PropTypes.array.isRequired,
  type: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  bold: PropTypes.bool,
  right: PropTypes.bool,
  left: PropTypes.bool,
};

export default H2;
export const H2Styles = Container;
