import React from 'react';
import {
  Container, Heading, Orders, Label,
} from './styled';
import OrderDetail from './OrderDetail';
const headings = ['Order', 'Total'];

function OrderHistory({ orders = [] }) {
  return (
    <Container>
      <Heading>
        {headings.map((label, index) => <Label key={index}>{label}</Label>)}
      </Heading>
      <Orders>
        {
          orders.map((order, index) => (
            <OrderDetail key={index} {...order} />
          ))
        }
      </Orders>
    </Container>
  );
}

export default OrderHistory;
