import React, { useState } from 'react';
import { connect } from 'react-redux';
import Button from '../../../../Button1';
import Column from '../../../../Column';
import Packages from '../../../../Packages';
import { updatePackage } from '../../../../../actions/userActions';
import {
  Heading, Title, SubTitle,
} from './styled.js';

function Details({ accountData, updatePackage }) {
  const { packageType } = accountData || {};
  const [selected, setSelected] = useState(packageType);
  return (
    <Column style={{
      margin: 30, backgroundColor: 'white', justifyContent: 'flex-start',
      width: '100%',
    }}>
      <Heading>
        <Title>Upgrade My Package</Title>
        <SubTitle>This information can be edited from you profile page</SubTitle>
      </Heading>
      <Packages disabled={packageType === 1} value={selected} update={setSelected} />
      <Button disabled={packageType === selected} onClick={() => updatePackage(selected)} style={{ width: 'calc(100% - 80px)' }} color="#7DBEC8">Confirm And Pay</Button>
    </Column>
  );
}


const mapDispatchToProps = { updatePackage };

export default connect(null, mapDispatchToProps)(Details);